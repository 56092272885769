.alarm-btn,
.alarm-btn-ok,
.alarm-btn-warn,
.alarm-btn-err {
    display: grid;
    justify-content: center;
    align-items: center;
    animation: shake 1s cubic-bezier(0.12, 0.9, 0.74, 0.71) infinite alternate-reverse;
    cursor: pointer;
    border: .5px solid var(--anim-btn-bg2);
    background-color: var(--anim-btn-bg2);
    &:hover {
        border: .5px solid var(--anim-btn-bg);
    }
    &, & > i, & > div {
        height: 30px;
        width: 30px;
        border-radius: 50%;
    }
    & > i {
        color: var(--anim-btn-bg);
        top: 3px;
        left: 4px;
        font-size: 20px;
    }
    & > div {
        animation: scalingBg 2s cubic-bezier(0.12, 0.9, 0.74, 0.71) infinite;
        &:first-child {
            animation-delay: 1s !important;
            animation: scalingBg2 2s cubic-bezier(0.12, 0.9, 0.74, 0.71) infinite;
        }
    }
    & > i, & > div {
        position: absolute;
    }
}

.alarm-btn {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @primaryColorL;
}

.alarm-btn-ok {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @okColor;
}

.alarm-btn-warn {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @warningColor;
}

.alarm-btn-err {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @errorColor;
}


@keyframes scalingBg {
    0% {
        transform: scale(var(--anim-btn-scale1, 1));
        background-color: var(--anim-btn-bg);
        opacity: .7;
    }
    100% {
        transform: scale(var(--anim-btn-scale2, 2));
        background-color: var(--anim-btn-bg2);
        opacity: .05;
    }
}

@keyframes scalingBg2 {
    0% {
        transform: scale(var(--anim-btn-scale1, 1));
        background-color: var(--anim-btn-bg2);
        opacity: .7;
    }
    100% {
        transform: scale(var(--anim-btn-scale2, 2));
        background-color: var(--anim-btn-bg);
        opacity: .05;
    }
}

@keyframes shake {
    0%   { transform: skew(1deg) scale(1)    rotate(0);      }
    10%  { transform: skew(1deg) scale(1.05) rotate(-25deg); }
    20%  { transform: skew(1deg) scale(1.1)  rotate(25deg);  }
    30%  { transform: skew(1deg) scale(1.15) rotate(-25deg); }
    40%  { transform: skew(1deg) scale(1.2)  rotate(25deg);  }
    50%  { transform: skew(1deg) scale(1.25) rotate(0);      }
    100% { transform: skew(1deg) scale(1.5)  rotate(0);      }
}

.wave-btn,
.wave-btn-ok,
.wave-btn-warn,
.wave-btn-err {
    display: grid;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: .5px solid var(--anim-btn-bg2);
    background-color: var(--anim-btn-bg2);
    position: relative;
    &:hover {
        box-shadow: 0 0 5px 2px var(--anim-btn-bg);
    }
    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        animation: scalingBg2 3s linear infinite both;
    }
    &, & > i, & > div {
        height: 30px;
        width: 30px;
        border-radius: 50%;
    }
    & > i {
        color: var(--anim-btn-bg);
        top: 3px;
        left: 4px;
        font-size: 20px;
    }
    & > div {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        & > div {
            opacity: .4;
            position: absolute;
            top: 35%;
            left: -15%;
            width: 130%;
            height: 130%;
            transform-origin: 50% 48%;
            animation: drift 3s infinite linear;
            background: none;
            border-radius: 35%;
            border: 2px solid white;
            &:nth-child(2) {
                animation: drift 7s infinite linear;
                opacity: 0.3;
                top: 40%;
                left: -5%;
            }
            &:nth-child(3) {
                animation: drift 5s infinite linear;
                opacity: 0.2;
                top: 44%;
                left: -35%;
            }
        }
    }
    & > i, & > div {
        position: absolute;
    }
}

.wave-btn {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @primaryColorL;
}

.wave-btn-ok {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @okColor;
}

.wave-btn-warn {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @warningColor;
}

.wave-btn-err {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @errorColor;
}

@keyframes drift {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.pulse-btn,
.pulse-btn-primary,
.pulse-btn-ok,
.pulse-btn-warn,
.pulse-btn-err {
    --anim-btn-scale1: .8;
    --anim-btn-scale2: 1.5;
    display: grid;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid var(--anim-btn-bg2);
    background-color: var(--anim-btn-bg);
    position: relative;
    border-radius: 50% !important;
    &::before,
    &::after {
        position: absolute;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        content: '';
    }
    &::before {
        z-index: 1;
        background-color: var(--anim-btn-bg);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        transition: color .3s;
    }
    &::after {
        animation: scalingBg 2s ease-in-out infinite both;
    }
    &:hover {
        &::before {
            background-color: var(--anim-btn-bg2);
        }
        & > i {
            color: var(--anim-btn-bg);
        }
    }
    & > i {
        transition: color .3s;
        color: var(--anim-btn-bg2);
        z-index: 1;
    }
    &.btn-noanim {
        &::before,
        &::after {
            animation: none;
        }
    }
    &.disabled {
        border: .5px dashed @textColorL;
        cursor: not-allowed;
        pointer-events: none;
        &::before,
        &::after {
            animation: none;
        }
        & > * {
            opacity: .5;
        }
    }
    &.ant-btn {
        border-width: 1px;
        border-color: var(--anim-btn-bg2);
        &:hover {
            & > i {
                color: var(--anim-btn-bg2);
            }
        }
    }
}

.pulse-btn {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @textColorL;
}

.pulse-btn-primary {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @primaryColorL;
}

.pulse-btn-ok {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @okColor;
}

.pulse-btn-warn {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @warningColor;
}

.pulse-btn-err {
    --anim-btn-bg: @bgColor;
    --anim-btn-bg2: @errorColor;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    80% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.anim-pulse {
    animation: pulse 1s ease-in-out infinite both;
}

.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    box-shadow: @shadow3;
}

.cdk-drag-animating {
    // transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-receiving {
    &.vz-drag-hl-bg:not(.vz-drag-start):not(.vz-drop-forbidden) {
        // box-shadow: inset -10px 0 20px 20px @okColorD;
        &:before {
            background-color: tint(@okColor, 80%);
            opacity: .5;
            width: calc(100% - 8px) !important;
            height: calc(100% - 8px) !important;
            margin: 4px;
            border: 1px dashed @okColorD;
        }
        // &:after {
        //     border: 1px dashed @okColorD;
        // }
    }
}
.cdk-drop-list-dragging {
    &.vz-hide-preview {
        .cdk-drag-placeholder {
            display: none;
        }
    }
    &.vz-drag-hl-bg:not(.vz-drag-start):not(.vz-drop-forbidden) {
        // box-shadow: inset -10px 0 20px 20px @okColorL;
        &:before {
            background-color: tint(@okColor, 60%);
            opacity: .7;
            width: 100% !important;
            height: 100% !important;
            margin: 0;
            border: 2px solid @okColor;
        }
        // &:after {
        // }
    }
}

.cdk-drop-list {
    &.vz-drag-hl-bg.vz-drop-forbidden:not(.vz-drag-start) {
        opacity: .5;
        &:before {
            background-color: tint(@errorColor, 80%);
            opacity: .8;
            width: calc(100% - 8px) !important;
            height: calc(100% - 8px) !important;
            margin: 4px;
        }
        &:after {
            z-index: 2;
            pointer-events: none;
            position: absolute;
            content: attr(data-error) "";
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            padding: 8px 16px;
            color: @errorColorD;
            transition: opacity .3s;
        }
    }
}