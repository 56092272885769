.hl-w {
    border: 1px solid @textColorLL;
    background-color: @bgColorD;
    border-radius: 2px;
    padding: 2px 4px;
    margin: 0 2px;
    color: @primaryColorD;
}

.hl-bg-fade {
    transition: background-color 4s linear .5s;
    &.hl-bg-ok {
        background-color: rgba(@okColor, .2);
        transition: none;
    }
    &.hl-bg-err {
        background-color: rgba(@errorColor, .2);
        transition: none;
    }
    &.hl-bg-p {
        background-color: rgba(@primaryColor, .2);
        transition: none;
    }
    &.hl-bg-pl {
        background-color: rgba(@primaryColorL, .2);
        transition: none;
    }
}

.hl-border-fade {
    transition: box-shadow .5s linear .5s;
    &.hl-border-ok {
        box-shadow: @okColor 0 0 3px 0;
        transition: none;
    }
    &.hl-border-err {
        box-shadow: @errorColor 0 0 3px 0;
        transition: none;
    }
    &.hl-border-p {
        box-shadow: @primaryColor 0 0 3px 0;
        transition: none;
    }
    &.hl-border-pl {
        box-shadow: @primaryColorL 0 0 3px 0;
        transition: none;
    }
}

.language-log {
    .hljs-dt {
        color: @debugColor;
    }
    .hljs-tag {
        color: @primaryColorD;
        font-weight: bold;
        background-color: @bgColorD;
        border-radius: 3px;
        padding: 2px;
        &.warn_ {
            color: darken(@warningColor, 10%);
        }
        &.error_ {
            color: @errorColorD;
        }
        &::before, &::after {
            font-weight: normal;
            font-size: calc(100% + 1px);
            opacity: .7;
            margin: 0 -2px;
        }
        &::before {
            content: "[";
        }
        &::after {
            content: "]";
        }
    }
    .hljs-url {
        color: @primaryColorL;
    }
    .hljs-log {
        color: @primaryColorD;
    }
    .hljs-warn {
        color: darken(@warningColor, 10%);
    }
    .hljs-error {
        color: @errorColorD;
    }
}
