.pe-none {
    pointer-events: none !important;
    * {
        pointer-events: none !important;
    }
}

.pe-all {
    pointer-events: all !important;
}

.pull-right {
    float: right;
}

.no-wrap {
    white-space: nowrap;
}

.force-wrap {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
}

.no-text {
    font-weight: bold;
    font-size: 28px;
    color: lighten(@primaryColorL, 30%);
}
small.no-text {
    font-size: 14px;
    font-weight: normal;
    color: lighten(@primaryColorL, 15%);
}

.fs-3xs { font-size: 6px !important; }
.fs-xxs { font-size: 8px !important; }
.fs-xs  { font-size: 10px !important; }
.fs-s   { font-size: 12px !important; }
.fs-m   { font-size: 14px !important; }
.fs-l   { font-size: 16px !important; }
.fs-xl  { font-size: 18px !important; }
.fs-xxl { font-size: 20px !important; }
.fs-3xl { font-size: 24px !important; }
.fs-4xl { font-size: 28px !important; }
.fs-5xl { font-size: 32px !important; }
.fs-6xl { font-size: 64px !important; }

h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
}
h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
}
h3 {
    font-weight: 600;
    font-size: 21px;
    line-height: 1.35;
}
h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 1.35;
}
h1 {
    font-weight: 600;
    font-size: 33px;
    line-height: 1.23;
}

.t-off {
    text-decoration: line-through lighten(@textColor, 15%) dashed;
}

.elli {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.elli-wrap {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    width: 100%;
}

.t-shadow-bg {
    text-shadow: 0 0 3px @bgColor, 0 0 3px @bgColor;
}