@import "../../node_modules/ng-zorro-antd/style/themes/default.less";
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// ant
@primary-color: #0060AA;

// local
@backdropColor: rgba(@text-color, .2);
@debugColor: #883;

@primaryColor: @primary-color;
@primaryColorL: lighten(@primary-color, 10%);
@primaryColorD: darken(@primary-color, 10%);

@accentColor: #1890ff;
@accentColor2: #ff8000;
@okColor: @success-color;
@okColorD: darken(@success-color, 10%);
@okColorL: lighten(@success-color, 10%);
@errorColor: #ff1018;
@errorColorD: darken(@errorColor, 20%);
@warningColor: @warning-color;
@infoColor: @accentColor;

@bgColor: #fff;
@bgColorD: darken(@bgColor, 4%);
@bgColorDD: darken(@bgColor, 8%);
@bgColorError: lighten(@errorColor, 25%);
@bgColorOk: lighten(@okColor, 30%);
@bgColorInv: #000;
@bgColorCode: #f6f8fa;

@textColor: @text-color;
@textColorL: lighten(@textColor, 35%);
@textColorLL: lighten(@textColor, 70%);
@textColorInv: @bgColor;
@textColorInvD: darken(@textColorInv, 8%);

@borderColor: darken(@bgColor, 12%);
@focusColor: @accentColor;

@shadowColor: @shadow-color;
@shadowColorD: fadein(@shadow-color, 25%);
@shadow1: 0 0 1px 1px @shadowColor;
@shadow2: 0 0 2px 1px @shadowColor;
@shadow3: 0 0 3px 1px @shadowColor;

@easeInOut: @ease-in-out;
@selectBg: rgba(@primaryColor, .1);

// Task priority colors
// @task-priority-colors: #ccc, #e53935, #df6334, #d88b34, #d2af34, #c5ca34, #99bf38, #74b53c, #56aa40, #43a047;
@task-priority-colors: #ccc, #e53935, #d2af34, #43a047;

@httpGetColor: #43a047;
@httpPostColor: #006ed4;
@httpPutColor: #d48a00;
@httpDeleteColor: #ac0000;

@btn-border-radius-base: 8px;
@btn-border-radius-sm: 6px;

body {
    b {
        font-weight: bold;
    }
    ::selection {
        color: @textColorInv;
        background: mix(@bgColor, @primaryColor, 20%);
    }
    --vz-primaryColor: @primaryColor;
    --vz-primaryColorL: @primaryColorL;
    --vz-primaryColorD: @primaryColorD;
    --vz-accentColor: @accentColor;
    --vz-accentColor2: @accentColor2;
    --vz-warningColor: @warningColor;
    --vz-errorColor: @errorColor;
    --vz-okColor: @okColor;
    --vz-selectBg: @selectBg;
    --vz-shadowColor: @shadowColor;
    --vz-bgColor: @bgColor;
    --vz-bgColorD: @bgColorD;
    --vz-bgColorDD: @bgColorDD;
    --vz-textColor: @textColor;
    --vz-textColorL: @textColorL;
    --vz-textColorLL: @textColorLL;
    --vz-borderColor: @borderColor;

    --vz-fadeButtonBg: rgba(@accentColor, .2);
    --vz-activeOrgBg: @primaryColorD;
    --vz-activeOrgBgL: tint(@primaryColorD, 10%);
    --vz-activeOrgBgLL: tint(@primaryColorD, 20%);
    --vz-activeOrgBgD: shade(@primaryColorD, 10%);
    --vz-activeOrgBgDD: shade(@primaryColorD, 20%);
}
