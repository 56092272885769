@keyframes spin {
    0% { transform: rotate(360deg); }
    100% { transform: rotate(0deg); }
}

@keyframes spinb {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.loader {
    margin: .063rem;
    border-top: 1.1em solid @white;
    border-right: 1.1em solid @white;
    border-bottom: 1.1em solid @white;
    border-left: 1.1em solid @blue-6;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin .5s infinite linear;
    animation: spin .5s infinite linear;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
}

.loader.medium {
    border-width: .5em;
}

.loader.medium,
.loader.medium:after {
    width: 5rem;
    height: 5rem;
}

.loader.small {
    border-width: .25rem;
    &.file-loader {
        border-width: .125rem;
        -webkit-animation: spinb .5s infinite linear;
        animation: spinb .5s infinite linear;
    }
}

.loader.small,
.loader.small:after {
    width: 2.5rem;
    height: 2.5rem;
}

.loader.small2 {
    border-width: .1875rem;
}

.loader.small2,
.loader.small2:after {
    width: 1.75rem;
    height: 1.75rem;
}

.loader.tiny {
    border-width: .125rem;
}

.loader.tiny,
.loader.tiny:after {
    width: 1.25rem;
    height: 1.25rem;
}

.loader.micro {
    border-width: .125rem;
}

.loader.micro,
.loader.micro:after {
    width: 1rem;
    height: 1rem;
}

.loader-text {
    font-size: 1.5rem;
    color: tint(@primaryColor, 80%);
    font-weight: bold;
    text-align: center;
    margin-left: .625rem;
}

.loader-text.small {
    font-size: 1.125rem;
}

.loader-text-error {
    color: tint(@error-color, 80%);
}
