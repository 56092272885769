.vz-progress {
    height: 10px;
    border: 1px solid @bgColor;
    border-radius: 2px;
    box-shadow: 0 0 1px 1px rgba(@primaryColorL, .4);
    & > div {
        height: 100%;
        width: 0;
        background-color: @okColorD;
    }
}

.vz-progress-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    transition: width .2s;
}