[class^="vzi-"],
[class*=" vzi-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'vizorro' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.vzi-filter_none:before {
    content: "\e9de";
}

.vzi-dashboard:before {
    content: "\ea03";
}

.vzi-movie:before {
    content: "\e990";
}

.vzi-pause_circle_outline:before {
    content: "\e9bf";
}

.vzi-play_circle_outline:before {
    content: "\e9c0";
}

.vzi-repeat:before {
    content: "\e929";
}

.vzi-video:before {
    content: "\e947";
}

.vzi-video_off:before {
    content: "\e949";
}

.vzi-volume_off:before {
    content: "\e941";
}

.vzi-volume_up:before {
    content: "\e942";
}

.vzi-web:before {
    content: "\e991";
}

.vzi-list-check:before {
    content: "\e900";
}

.vzi-featured_video:before {
    content: "\e992";
}

.vzi-call:before {
    content: "\e901";
}

.vzi-call_end:before {
    content: "\e963";
}

.vzi-call_split:before {
    content: "\e98f";
}

.vzi-live_help:before {
    content: "\e964";
}

.vzi-location_off:before {
    content: "\e965";
}

.vzi-location_on:before {
    content: "\e966";
}

.vzi-contact_phone:before {
    content: "\e993";
}

.vzi-contact_mail:before {
    content: "\e994";
}

.vzi-mail:before {
    content: "\e902";
}

.vzi-email:before {
    content: "\e903";
}

.vzi-block:before {
    content: "\e943";
}

.vzi-cross:before {
    content: "\e904";
}

.vzi-copy:before {
    content: "\e944";
}

.vzi-cut:before {
    content: "\e945";
}

.vzi-paste:before {
    content: "\e946";
}

.vzi-reply:before {
    content: "\e906";
}

.vzi-inbox:before {
    content: "\e905";
}

.vzi-attach:before {
    content: "\e907";
}

.vzi-edit1:before {
    content: "\e935";
}

.vzi-cast:before {
    content: "\e97e";
}

.vzi-cast_connected:before {
    content: "\e985";
}

.vzi-desktop_windows:before {
    content: "\e986";
}

.vzi-ch-down:before {
    content: "\e909";
}

.vzi-ch-left:before {
    content: "\e90a";
}

.vzi-ch-right:before {
    content: "\e90b";
}

.vzi-ch-up:before {
    content: "\e90c";
}

.vzi-return:before {
    content: "\e90d";
}

.vzi-tablet_android:before {
    content: "\e967";
}

.vzi-tv:before {
    content: "\e968";
}

.vzi-device_hub:before {
    content: "\e9ca";
}

.vzi-camera_alt:before {
    content: "\e97f";
}

.vzi-camera_front:before {
    content: "\e980";
}

.vzi-camera_rear:before {
    content: "\e981";
}

.vzi-palette:before {
    content: "\e90e";
}

.vzi-flip:before {
    content: "\e936";
}

.vzi-rotate-left:before {
    content: "\e995";
}

.vzi-rotate-right:before {
    content: "\e996";
}

.vzi-switch_video:before {
    content: "\e94a";
}

.vzi-timer:before {
    content: "\e90f";
}

.vzi-tune:before {
    content: "\e9ba";
}

.vzi-arrow-left:before {
    content: "\e911";
}

.vzi-arrow-right:before {
    content: "\e912";
}

.vzi-check:before {
    content: "\e908";
}

.vzi-arrow-up:before {
    content: "\e913";
}

.vzi-arrow-down:before {
    content: "\e914";
}

.vzi-phone_in_talk:before {
    content: "\e969";
}

.vzi-phone_missed:before {
    content: "\e96a";
}

.vzi-ondemand_video:before {
    content: "\e94b";
}

.vzi-phone_callback:before {
    content: "\e96b";
}

.vzi-person:before {
    content: "\e918";
}

.vzi-person-o:before {
    content: "\e919";
}

.vzi-done:before {
    content: "\e948";
}

.vzi-history:before {
    content: "\e97c";
}

.vzi-home:before {
    content: "\e91b";
}

.vzi-input:before {
    content: "\e982";
}

.vzi-launch:before {
    content: "\e983";
}

.vzi-search:before {
    content: "\e91d";
}

.vzi-settings:before {
    content: "\e91c";
}

.vzi-settings_ethernet:before {
    content: "\e987";
}

.vzi-tab:before {
    content: "\e93b";
}

.vzi-layout:before {
    content: "\e93c";
}

.vzi-update:before {
    content: "\e97d";
}

.vzi-tree:before {
    content: "\e91e";
}

.vzi-desktop_access_disabled:before {
    content: "\e99a";
}

.vzi-imagesearch_roller:before {
    content: "\e9bc";
}

.vzi-logout:before {
    content: "\e920";
}

.vzi-speed:before {
    content: "\e916";
}

.vzi-tag:before {
    content: "\e9bd";
}

.vzi-group:before {
    content: "\e91f";
}

.vzi-emoji_events:before {
    content: "\e96c";
}

.vzi-camera_flip:before {
    content: "\e984";
}

.vzi-cancel-send:before {
    content: "\e92d";
}

.vzi-house:before {
    content: "\e96d";
}

.vzi-agriculture:before {
    content: "\e96e";
}

.vzi-alt_route:before {
    content: "\e98e";
}

.vzi-anchor1:before {
    content: "\e96f";
}

.vzi-campaign:before {
    content: "\e970";
}

.vzi-construction:before {
    content: "\e910";
}

.vzi-worker:before {
    content: "\e921";
}

.vzi-send-mail:before {
    content: "\e915";
}

.vzi-all-tasks:before {
    content: "\e940";
}

.vzi-history_toggle_off:before {
    content: "\e978";
}

.vzi-local_police:before {
    content: "\e971";
}

.vzi-multiple_stop:before {
    content: "\e9be";
}

.vzi-outbox:before {
    content: "\e922";
}

.vzi-working:before {
    content: "\e924";
}

.vzi-qr_code:before {
    content: "\e972";
}

.vzi-qr_code_scanner:before {
    content: "\e973";
}

.vzi-rule:before {
    content: "\e9cc";
}

.vzi-star-o:before {
    content: "\e925";
}

.vzi-star:before {
    content: "\e926";
}

.vzi-support_agent:before {
    content: "\e974";
}

.vzi-tty:before {
    content: "\e94d";
}

.vzi-video_settings:before {
    content: "\e94c";
}

.vzi-team2:before {
    content: "\e927";
}

.vzi-menu:before {
    content: "\e94f";
}

.vzi-chat1:before {
    content: "\e988";
}

.vzi-chat-1:before {
    content: "\e989";
}

.vzi-chat-2:before {
    content: "\e98a";
}

.vzi-note:before {
    content: "\e937";
}

.vzi-comment:before {
    content: "\e98b";
}

.vzi-send:before {
    content: "\e930";
}

.vzi-var:before {
    content: "\e9dc";
}

.vzi-integration2:before {
    content: "\e9d6";
}

.vzi-integration3:before {
    content: "\e9d7";
}

.vzi-integration:before {
    content: "\e9d8";
}

.vzi-integration4:before {
    content: "\e9d9";
}

.vzi-webhooks:before {
    content: "\e9db";
}

.vzi-stack:before {
    content: "\e9d4";
}

.vzi-kanban3:before {
    content: "\e9d1";
}

.vzi-kanban2:before {
    content: "\e9d2";
}

.vzi-kanban:before {
    content: "\e9d3";
}

.vzi-share-screen:before {
    content: "\e9b8";
}

.vzi-team:before {
    content: "\e97b";
}

.vzi-org:before {
    content: "\e97a";
}

.vzi-idea:before {
    content: "\e977";
}

.vzi-crown:before {
    content: "\e923";
}

.vzi-mobile:before {
    content: "\e928";
}

.vzi-lock:before {
    content: "\e92a";
}

.vzi-fullscreen-exit:before {
    content: "\e94e";
}

.vzi-fullscreen:before {
    content: "\e950";
}

.vzi-list1:before {
    content: "\e93e";
}

.vzi-list-nested:before {
    content: "\e93f";
}

.vzi-out:before {
    content: "\e92b";
}

.vzi-in:before {
    content: "\e92c";
}

.vzi-pacman:before {
    content: "\e952";
}

.vzi-spades:before {
    content: "\e953";
}

.vzi-clubs:before {
    content: "\e954";
}

.vzi-diamonds:before {
    content: "\e955";
}

.vzi-barcode:before {
    content: "\e956";
}

.vzi-qrcode:before {
    content: "\e957";
}

.vzi-undo:before {
    content: "\e98c";
}

.vzi-redo:before {
    content: "\e98d";
}

.vzi-pie-chart1:before {
    content: "\e9dd";
}

.vzi-stats-dots:before {
    content: "\e99b";
}

.vzi-stats-bars:before {
    content: "\e99c";
}

.vzi-trophy:before {
    content: "\e99e";
}

.vzi-gift:before {
    content: "\e99f";
}

.vzi-glass:before {
    content: "\e9a0";
}

.vzi-spoon-knife:before {
    content: "\e9a3";
}

.vzi-hammer2:before {
    content: "\e9a8";
}

.vzi-fire:before {
    content: "\e9a9";
}

.vzi-lab:before {
    content: "\e9cd";
}

.vzi-airplane:before {
    content: "\e9af";
}

.vzi-truck:before {
    content: "\e9b0";
}

.vzi-switch:before {
    content: "\e9b6";
}

.vzi-list-numbered:before {
    content: "\e9b9";
}

.vzi-list2:before {
    content: "\e9bb";
}

.vzi-menu1:before {
    content: "\e9c9";
}

.vzi-cloud-download:before {
    content: "\e9c2";
}

.vzi-cloud-upload:before {
    content: "\e9c3";
}

.vzi-link:before {
    content: "\e9cb";
}

.vzi-heart:before {
    content: "\e9da";
}

.vzi-happy:before {
    content: "\e9df";
}

.vzi-happy2:before {
    content: "\e9e0";
}

.vzi-smile:before {
    content: "\e9e1";
}

.vzi-smile2:before {
    content: "\e9e2";
}

.vzi-tongue:before {
    content: "\e9e3";
}

.vzi-tongue2:before {
    content: "\e9e4";
}

.vzi-sad:before {
    content: "\e9e5";
}

.vzi-sad2:before {
    content: "\e9e6";
}

.vzi-wink:before {
    content: "\e9e7";
}

.vzi-wink2:before {
    content: "\e9e8";
}

.vzi-grin:before {
    content: "\e9e9";
}

.vzi-grin2:before {
    content: "\e9ea";
}

.vzi-cool:before {
    content: "\e9eb";
}

.vzi-cool2:before {
    content: "\e9ec";
}

.vzi-angry:before {
    content: "\e9ed";
}

.vzi-angry2:before {
    content: "\e9ee";
}

.vzi-evil:before {
    content: "\e9ef";
}

.vzi-evil2:before {
    content: "\e9f0";
}

.vzi-shocked:before {
    content: "\e9f1";
}

.vzi-shocked2:before {
    content: "\e9f2";
}

.vzi-baffled:before {
    content: "\e9f3";
}

.vzi-baffled2:before {
    content: "\e9f4";
}

.vzi-confused:before {
    content: "\e9f5";
}

.vzi-confused2:before {
    content: "\e9f6";
}

.vzi-neutral:before {
    content: "\e9f7";
}

.vzi-neutral2:before {
    content: "\e9f8";
}

.vzi-hipster:before {
    content: "\e9f9";
}

.vzi-hipster2:before {
    content: "\e9fa";
}

.vzi-wondering:before {
    content: "\e9fb";
}

.vzi-wondering2:before {
    content: "\e9fc";
}

.vzi-sleepy:before {
    content: "\e9fd";
}

.vzi-sleepy2:before {
    content: "\e9fe";
}

.vzi-frustrated:before {
    content: "\e9ff";
}

.vzi-frustrated2:before {
    content: "\ea00";
}

.vzi-crying:before {
    content: "\ea01";
}

.vzi-crying2:before {
    content: "\ea02";
}

.vzi-warning:before {
    content: "\ea07";
}

.vzi-notification:before {
    content: "\ea08";
}

.vzi-question:before {
    content: "\ea09";
}

.vzi-info:before {
    content: "\ea0c";
}

.vzi-cancel-circle:before {
    content: "\ea0d";
}

.vzi-blocked:before {
    content: "\ea0e";
}

.vzi-close:before {
    content: "\ea0f";
}

.vzi-enter:before {
    content: "\ea13";
}

.vzi-exit:before {
    content: "\ea14";
}

.vzi-infinite:before {
    content: "\ea2f";
}

.vzi-radio-checked2:before {
    content: "\ea55";
}

.vzi-filter:before {
    content: "\ea5b";
}

.vzi-bold:before {
    content: "\ea62";
}

.vzi-underline:before {
    content: "\ea63";
}

.vzi-italic:before {
    content: "\ea64";
}

.vzi-strikethrough:before {
    content: "\ea65";
}

.vzi-omega:before {
    content: "\ea66";
}

.vzi-sigma:before {
    content: "\ea67";
}

.vzi-superscript:before {
    content: "\ea69";
}

.vzi-subscript:before {
    content: "\ea6a";
}

.vzi-table2:before {
    content: "\ea71";
}

.vzi-indent-increase:before {
    content: "\ea7b";
}

.vzi-indent-decrease:before {
    content: "\ea7c";
}

.vzi-embed2:before {
    content: "\ea80";
}

.vzi-google:before {
    content: "\ea88";
}

.vzi-facebook:before {
    content: "\ea91";
}

.vzi-instagram:before {
    content: "\ea92";
}

.vzi-whatsapp:before {
    content: "\ea93";
}

.vzi-telegram:before {
    content: "\ea95";
}

.vzi-twitter:before {
    content: "\ea96";
}

.vzi-dropbox:before {
    content: "\eaae";
}

.vzi-github:before {
    content: "\eab0";
}

.vzi-windows:before {
    content: "\eac1";
}

.vzi-skype:before {
    content: "\eac5";
}

.vzi-linkedin:before {
    content: "\eac9";
}

.vzi-pinterest:before {
    content: "\ead2";
}

.vzi-tag-lines:before {
    content: "\e92e";
}

.vzi-cabinet:before {
    content: "\e9d5";
}

.vzi-calendar1:before {
    content: "\e93a";
}

.vzi-calendar2:before {
    content: "\e93d";
}

.vzi-file1:before {
    content: "\e9ce";
}

.vzi-file2:before {
    content: "\e9cf";
}

.vzi-window:before {
    content: "\e99d";
}

.vzi-mug:before {
    content: "\e975";
}

.vzi-project:before {
    content: "\e92f";
}

.vzi-medal:before {
    content: "\e958";
}

.vzi-key:before {
    content: "\e931";
}

.vzi-layout2:before {
    content: "\e9a1";
}

.vzi-layout3:before {
    content: "\e9a2";
}

.vzi-layout1:before {
    content: "\e999";
}

.vzi-layout4:before {
    content: "\e9a4";
}

.vzi-layout5:before {
    content: "\e9a5";
}

.vzi-layout6:before {
    content: "\e9a6";
}

.vzi-layout7:before {
    content: "\e9a7";
}

.vzi-layout8:before {
    content: "\e9aa";
}

.vzi-resize:before {
    content: "\e9ab";
}

.vzi-resize1:before {
    content: "\e9ac";
}

.vzi-attachment:before {
    content: "\e932";
}

.vzi-bookmark2:before {
    content: "\e938";
}

.vzi-addressbook:before {
    content: "\e933";
}

.vzi-rain:before {
    content: "\e959";
}

.vzi-snow:before {
    content: "\e95a";
}

.vzi-lightning:before {
    content: "\e95b";
}

.vzi-sun:before {
    content: "\e95c";
}

.vzi-moon:before {
    content: "\e95d";
}

.vzi-cloudy:before {
    content: "\e95e";
}

.vzi-rocket:before {
    content: "\e917";
}

.vzi-expand:before {
    content: "\e9ad";
}

.vzi-collapse:before {
    content: "\e9ae";
}

.vzi-popout:before {
    content: "\e9b1";
}

.vzi-popin:before {
    content: "\e9b2";
}

.vzi-checked:before {
    content: "\e9b3";
}

.vzi-error:before {
    content: "\e9b4";
}

.vzi-add:before {
    content: "\e9b5";
}

.vzi-minus:before {
    content: "\e9b7";
}

.vzi-shipping:before {
    content: "\e976";
}

.vzi-yingyang:before {
    content: "\e95f";
}

.vzi-anchor:before {
    content: "\e960";
}

.vzi-magnet:before {
    content: "\e961";
}

.vzi-tags:before {
    content: "\e934";
}

.vzi-completed:before {
    content: "\e91a";
}

.vzi-switch1:before {
    content: "\e962";
}

.vzi-plus:before {
    content: "\f067";
}

.vzi-remove:before {
    content: "\f00d";
}

.vzi-trash-o:before {
    content: "\f014";
}

.vzi-file:before {
    content: "\f016";
}

.vzi-image:before {
    content: "\f03e";
}

.vzi-photo:before {
    content: "\f03e";
}

.vzi-picture-o:before {
    content: "\f03e";
}

.vzi-edit:before {
    content: "\f040";
}

.vzi-forward:before {
    content: "\f064";
}

.vzi-eye:before {
    content: "\f06e";
}

.vzi-eye-slash:before {
    content: "\f070";
}

.vzi-calendar:before {
    content: "\f073";
}

.vzi-thumbs-o-up:before {
    content: "\f087";
}

.vzi-thumbs-o-down:before {
    content: "\f088";
}

.vzi-bell-o:before {
    content: "\f0a2";
}

.vzi-floppy:before {
    content: "\f0c7";
}

.vzi-comment-o:before {
    content: "\f0e5";
}

.vzi-chat:before {
    content: "\f0e6";
}

.vzi-bell:before {
    content: "\f0f3";
}

.vzi-file-text:before {
    content: "\f0f6";
}

.vzi-code-fork:before {
    content: "\f126";
}

.vzi-mic:before {
    content: "\f130";
}

.vzi-mic-off:before {
    content: "\f131";
}

.vzi-3dots:before {
    content: "\f142";
}

.vzi-level-up:before {
    content: "\f148";
}

.vzi-level-down:before {
    content: "\f149";
}

.vzi-sort-asc:before {
    content: "\f160";
}

.vzi-sort-desc:before {
    content: "\f161";
}

.vzi-youtube:before {
    content: "\f167";
}

.vzi-bug:before {
    content: "\f188";
}

.vzi-vk:before {
    content: "\f189";
}

.vzi-paw:before {
    content: "\f1b0";
}

.vzi-tree1:before {
    content: "\f1bb";
}

.vzi-file-pdf:before {
    content: "\f1c1";
}

.vzi-file-word:before {
    content: "\f1c2";
}

.vzi-file-excel:before {
    content: "\f1c3";
}

.vzi-file-pp:before {
    content: "\f1c4";
}

.vzi-file-image:before {
    content: "\f1c5";
}

.vzi-file-archive:before {
    content: "\f1c6";
}

.vzi-file-audio:before {
    content: "\f1c7";
}

.vzi-file-video:before {
    content: "\f1c8";
}

.vzi-file-code:before {
    content: "\f1c9";
}

.vzi-share-alt:before {
    content: "\f1e0";
}

.vzi-birthday-cake:before {
    content: "\f1fd";
}

.vzi-pie-chart:before {
    content: "\f200";
}

.vzi-user-add:before {
    content: "\f234";
}

.vzi-user-del:before {
    content: "\f235";
}

.vzi-balance-scale:before {
    content: "\f24e";
}

.vzi-hand-peace-o:before {
    content: "\f25b";
}

.vzi-site:before {
    content: "\f26b";
}

.vzi-calendar-plus-o:before {
    content: "\f271";
}

.vzi-calendar-minus-o:before {
    content: "\f272";
}

.vzi-calendar-times-o:before {
    content: "\f273";
}

.vzi-calendar-check-o:before {
    content: "\f274";
}

.vzi-controller-fast-forward:before {
    content: "\e9c1";
}

.vzi-controller-jump-to-start:before {
    content: "\e9c4";
}

.vzi-controller-next:before {
    content: "\e9c5";
}

.vzi-controller-paus:before {
    content: "\e9c6";
}

.vzi-controller-play:before {
    content: "\e9c7";
}

.vzi-record:before {
    content: "\e979";
}

.vzi-controller-stop:before {
    content: "\e9c8";
}

.vzi-flow-cascade:before {
    content: "\e9d0";
}

.vzi-bookmark:before {
    content: "\e939";
}

.vzi-headphones:before {
    content: "\e951";
}

.vzi-zoom-in:before {
    content: "\e997";
}

.vzi-zoom-out:before {
    content: "\e998";
}