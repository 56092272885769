.hover-up4, .hover-up8 {
    transition: all .2s @easeInOut;
    cursor: pointer;
    &:hover {
        z-index: 1;
    }
}

.hover-up4:hover {
    margin-top: -4px;
}
.hover-up8:hover {
    margin-top: -8px;
}

.hover-border {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        transition: all .3s;
        pointer-events: none;
    }
    &:hover:after {
        box-shadow: inset rgba(@primaryColor, .7) 0 0 3px 0;
        transition: none;
    }
    &.hover-selected:after {
        background-color: rgba(@primaryColor, .1) !important;
    }
}

.hover-hl {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        transition: all .3s;
        pointer-events: none;
    }
    &:hover:after {
        box-shadow: inset rgba(@primaryColor, .1) 0 0 100px 0;
        transition: none;
    }
    &.hover-selected:after {
        background-color: rgba(@primaryColor, .1) !important;
    }
}

.shadow-border-p {
    box-shadow: inset rgba(@primaryColor, .7) 0 0 3px 0;
}

.of-h {
    overflow: hidden;
}

.c-p {
    cursor: pointer !important;
}

.c-d {
    cursor: default !important;
}

.c-g {
    cursor: grab !important;
}

.c-gg {
    cursor: grabbing !important;
}

.r90 {
    transform: rotate(90deg);
}

.r180 {
    transform: rotate(180deg);
}

.r270 {
    transform: rotate(270deg);
}

.vz-disabled {
    pointer-events: none;
    color: @textColorL;
    opacity: .5;
}

a.disabled {
    pointer-events: none;
    color: @textColorL;
    cursor: default;
    & > span, & > span > * {
        color: @textColorL;
    }
}

.vz-expand {
    transition: transform 0.2s ease-out;
    cursor: pointer;
    line-height: 1;
    margin-top: -1px !important;
    display: inline-block;
    &.vz-expanded {
        transform: rotate(90deg);
    }
    &.vz-expanded180 {
        transform: rotate(180deg);
    }
}

.vz-sep-line,
.vz-sep-line-p,
.vz-sep-line-bg-d {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        border-top: 1px solid @borderColor;
        width: 100%;
        transform: translateY(calc(-50% - 0.5px));
    }
    & > span {
        padding: 2px 8px;
        background-color: @bgColor;
        z-index: 1;
    }
    &.sticky {
        top: 0;
        position: sticky;
        z-index: 1;
        & > span {
            z-index: 1;
        }
    }
}
.vz-sep-line-p:before {
    border-top-color: @primaryColorL;
}
.vz-sep-line-bg-d:before > span {
    border-top-color: @bgColorD;
}

hr {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid  @borderColor;
    margin: 0 0 4px 0;
    height: 4px;
}

.vsep {
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-right: 1px solid  @borderColor;
    margin: 6px 4px 6px 0;
    width: 4px;
}

.op0  { opacity: 0; }
.op01 { opacity: .1; }
.op02 { opacity: .2; }
.op03 { opacity: .3; }
.op04 { opacity: .4; }
.op05 { opacity: .5; }
.op06 { opacity: .6; }
.op08 { opacity: .8; }

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

.fade3 {
    animation: fadeOut 1s ease-in 3s forwards;
}

.fade5 {
    animation: fadeOut 1s ease-in 5s forwards;
}

.line-over-25 {
    overflow: hidden;
    margin-right: 25px !important;
}

.pull-l {
    position: absolute;
    left: 0;
}

.pull-r {
    position: absolute;
    right: 0;
}

.w50 {
    width: 50%;
}
.w100 {
    width: 100%;
}
.w300px {
    width: 300px;
}

.hide {
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
}

.hidden {
    display: none;
    pointer-events: none;
}

.d-ib {
    display: inline-block;
}

.b1s {
    border: 1px solid;
}
.b1dot {
    border: 1px dotted;
}
.b1dash {
    border: 1px dashed;
}

.br4 {
    border-radius: 4px;
}
.br4t {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.br6 {
    border-radius: 6px;
}
.br6t {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.br8 {
    border-radius: 8px;
}
.br8t {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.br8b {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.br50p {
    border-radius: 50%;
}

.fade-bottom {
    mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
    -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
}

.vz-fade-toolbar, .vz-fade-button {
    opacity: 0;
    transition: opacity 2s;
}
.vz-fade-toolbar {
    opacity: 0;
    transition: opacity 2s;
    position: absolute;
    background-color: rgba(@bgColor, .8);
}
&.vz-justmoved {
    .vz-fade-toolbar,
    .vz-fade-button {
        transition: none;
        opacity: 1;
    }
}

.zi2 {
    z-index: 2;
}
.zi5 {
    z-index: 5;
}
.zi20 {
    z-index: 20;
}
.zi9999 {
    z-index: 9999;
}

.vz-miniscroll {
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: @bgColorD;
        border-radius: 10px;
        &:hover {
            background-color: @bgColorDD;
        }
    }
}

.center-vert {
    top: 50%;
    transform: translateY(-50%);
}

pre.vz-code {
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    padding: 8px 12px;
    font-size: 85%;
    background-color: @bgColorCode;
    border-radius: 6px;
    color: @primaryColorD;
    border: 1px solid @borderColor;
    position: relative;
}

.bg-cell25 {
    background: @bgColor;
    background-size: 25px 25px;
    background-image: linear-gradient(to right, @bgColorCode 1px, transparent 1px), linear-gradient(to bottom, @bgColorCode 1px, transparent 1px);
}

.no-select {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}
