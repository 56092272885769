.txt        { color: @textColor; }
.txt-l      { color: @textColorL; }
.txt-ll     { color: @textColorLL; }
.txt-p      { color: @primaryColor; }
.txt-pl     { color: @primaryColorL; }
.txt-pd     { color: @primaryColorD; }
.txt-ok     { color: @okColorD; }
.txt-ok-l   { color: @okColor; }
.txt-ok-ll   { color: @okColorL; }
.txt-warn   { color: darken(@warningColor, 10%); }
.txt-error  { color: @errorColor; }
.txt-err    { color: @errorColor; }
.txt-err-d  { color: @errorColorD; }
.txt-a      { color: @accentColor; }
.txt-debug  { color: @debugColor; }
.txt-inv    { color: @bgColor; }
.f-txt        { color: @textColor !important; }
.f-txt-l      { color: @textColorL !important; }
.f-txt-ll     { color: @textColorLL !important; }
.f-txt-p      { color: @primaryColor !important; }
.f-txt-pl     { color: @primaryColorL !important; }
.f-txt-pd     { color: @primaryColorD !important; }
.f-txt-ok     { color: @okColorD !important; }
.f-txt-ok-l   { color: @okColor !important; }
.f-txt-ok-ll  { color: @okColorL !important; }
.f-txt-warn   { color: darken(@warningColor, 10%) !important; }
.f-txt-error  { color: @errorColor !important; }
.f-txt-err    { color: @errorColor !important; }
.f-txt-err-d  { color: @errorColorD !important; }
.f-txt-a      { color: @accentColor !important; }
.f-txt-debug  { color: @debugColor !important; }
.f-txt-inv    { color: @bgColor !important; }

.bg         { background-color: @bgColor; }
.bg-shade2  { background-color: rgba(@bgColorInv, .2); }
.bg-shade4  { background-color: rgba(@bgColorInv, .4); }
.bg-light8  { background-color: rgba(@bgColor, .8); }
.bg-shade05-err { background-color: rgba(@errorColor, .05); }
.bg-tr      { background-color: transparent; }
.bg-d       { background-color: @bgColorD; }
.bg-dd      { background-color: @bgColorDD; }
.bg-l       { background-color: @textColorL; }
.bg-ll      { background-color: @textColorLL; }
.bg-p       { background-color: @primaryColor; }
.bg-pl      { background-color: @primaryColorL; }
.bg-pd      { background-color: @primaryColorD; }
.bg-a       { background-color: @accentColor; }
.bg-ok      { background-color: @okColor; }
.bg-ok-l    { background-color: @bgColorOk; }
.bg-warn    { background-color: @warningColor; }
.bg-error   { background-color: @errorColor; }
.bg-err     { background-color: @errorColor; }
.bg-error-l { background-color: @bgColorError; }
.bg-err-l   { background-color: @bgColorError; }
.f-bg         { background-color: @bgColor !important; }
.f-bg-shade   { background-color: rgba(@bgColorInv, .8) !important; }
.f-bg-tr      { background-color: transparent !important; }
.f-bg-d       { background-color: @bgColorD !important; }
.f-bg-dd      { background-color: @bgColorDD !important; }
.f-bg-l       { background-color: @textColorL !important; }
.f-bg-ll      { background-color: @textColorLL !important; }
.f-bg-p       { background-color: @primaryColor !important; }
.f-bg-pl      { background-color: @primaryColorL !important; }
.f-bg-pd      { background-color: @primaryColorD !important; }
.f-bg-a       { background-color: @accentColor !important; }
.f-bg-ok      { background-color: @okColor !important; }
.f-bg-warn    { background-color: @warningColor !important; }
.f-bg-error   { background-color: @errorColor !important; }
.f-bg-err     { background-color: @errorColor !important; }

.bg-t-a       { background-color: tint(@accentColor, 90%); }
.bg-t-ok      { background-color: tint(@okColor, 90%); }
.bg-t-info    { background-color: tint(@infoColor, 90%); }
.bg-t-warn    { background-color: tint(@warningColor, 90%); }
.bg-t-error   { background-color: tint(@errorColor, 90%); }
.bg-t-err     { background-color: tint(@errorColor, 90%); }
.f-bg-t-a       { background-color: tint(@accentColor, 90%) !important; }
.f-bg-t-ok      { background-color: tint(@okColor, 90%) !important; }
.f-bg-t-info    { background-color: tint(@infoColor, 90%) !important; }
.f-bg-t-warn    { background-color: tint(@warningColor, 90%) !important; }
.f-bg-t-error   { background-color: tint(@errorColor, 90%) !important; }
.f-bg-t-err     { background-color: tint(@errorColor, 90%) !important; }

.bc         { border-color: @borderColor !important; }
.bc-tr      { border-color: transparent !important; }
.bc-bg      { border-color: @bgColor !important; }
.bc-bg-d    { border-color: @bgColorD !important; }
.bc-bg-dd   { border-color: @bgColorDD !important; }
.bc-l       { border-color: @textColorL !important; }
.bc-ll      { border-color: @textColorLL !important; }
.bc-p       { border-color: @primaryColor !important; }
.bc-pl      { border-color: @primaryColorL !important; }
.bc-pd      { border-color: @primaryColorD !important; }
.bc-a       { border-color: @accentColor !important; }
.bc-ok      { border-color: @okColor !important; }
.bc-warn    { border-color: @warningColor !important; }
.bc-error   { border-color: @errorColor !important; }
.bc-err     { border-color: @errorColor !important; }

.bsh, .bsh1  { box-shadow: @shadow1; }
.bsh2        { box-shadow: @shadow2; }
.bsh3        { box-shadow: @shadow3; }
.bsh-bg      { box-shadow: 0 0 3px 1px @bgColor; }
.bsh-bg-d    { box-shadow: 0 0 3px 1px @bgColorD; }
.bsh-bg-dd   { box-shadow: 0 0 3px 1px @bgColorDD; }
.bsh-l       { box-shadow: 0 0 3px 1px @textColorL; }
.bsh-ll      { box-shadow: 0 0 3px 1px @textColorLL; }
.bsh-p       { box-shadow: 0 0 3px 1px @primaryColor; }
.bsh-pl      { box-shadow: 0 0 3px 1px @primaryColorL; }
.bsh-pd      { box-shadow: 0 0 3px 1px @primaryColorD; }
.bsh-a       { box-shadow: 0 0 3px 1px @accentColor; }
.bsh-ok      { box-shadow: 0 0 3px 1px @okColor; }
.bsh-warn    { box-shadow: 0 0 3px 1px @warningColor; }
.bsh-error   { box-shadow: 0 0 3px 1px @errorColor; }
.bsh-err     { box-shadow: 0 0 3px 1px @errorColor; }
