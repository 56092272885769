.vz-chat-send {
    padding: 5px 5px 5px 7px;
    font-size: 24px;
    top: 16px;
    right: 16px;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px @shadowColor;
    color: @primaryColor;
    cursor: pointer;
    line-height: 1;
    position: absolute;            
    &:hover {
        box-shadow: 0 0 4px 4px rgba(@primaryColor, .2);
    }
}

.chat-icon-status {
    padding: 0 6px;
    font-size: 16px;
    line-height: 1;
}
.chat-status-1 {
    color: @errorColor;
}
.chat-status-2 {
    color: @okColorD;
}
.chat-status-3 {
    color: @textColorL;
}
.chat-status-4 {
    color: @textColorLL;
}
