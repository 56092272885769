.vz-resize-right,
.vz-resize-bottom,
.vz-resize-bottom-right {
    position: absolute;
    border-color: transparent;
    transition: border-color .3s cubic-bezier(0.19, 1, 0.22, 1) .3s;
    &:hover, &:active {
        border-color: @primaryColorL;
    }

}

.vz-resize-right {
    cursor: ew-resize;
    border-right: 3px solid transparent;
    top: 0;
    right: 0;
    bottom: 0;
    width: 7px;
}

.vz-resize-bottom {
    cursor: ns-resize;
    border-bottom: 3px solid transparent;
    left: 0;
    right: 0;
    bottom: 0;
    height: 7px;
}

.vz-resize-bottom-right {
    cursor: nwse-resize;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    bottom: 0;
    right: 0;
    width: 12px;
    height: 12px;
}
