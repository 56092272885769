.tree-lines {
    width: 30px;
    & > div {
        width: 30px;
    }
    .tree-vert-line,
    .tree-vert-line-last,
    .tree-vert-line-t {
        overflow: hidden;
        width: 30px;
        height: 100%;
        & > div {
            margin-left: 8px;
            border-width: 0;
            border-style: solid;
            border-color: rgba(@primaryColor, 0.3);
            & > div {
                width: 0;
                height: 0;
                border-width: 0;
                border-style: solid;
                border-color: rgba(@primaryColor, 0.3);
            }
        }
        &.tree-disabled > div,
        &.tree-disabled > div > div {
            border-color: @borderColor;
        }
        &.tree-selected > div,
        &.tree-selected > div > div {
            border-color: @accentColor;
        }
        &.tree-selected-parent > div,
        &.tree-selected-parent > div > div {
            border-color: rgba(@primaryColor, 0.8);
        }
    }
    .tree-vert-line {
        & > div {
            width: 1px;
            height: 100%;
            border-left-width: 2px;
        }
    }
    .tree-vert-line-last {
        & > div {
            width: 14px;
            height: 13px;
            border-left-width: 2px;
            border-bottom-width: 2px;
        }
    }
    .tree-vert-line-t {
        & > div {
            height: 100%;
            border-left-width: 2px;
            & > div {
                width: 12px;
                height: 12px;
                border-bottom-width: 2px;
            }
        }
    }
}
