/* Make sure li selections wrap around markers */
@keyframes ProseMirror-cursor-blink {
    to {
        visibility: hidden;
    }
}

/* Add space around the hr to make clicking it easier */
.ProseMirror {
    position: relative;
    word-wrap: break-word;
    white-space: pre-wrap;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    padding: 2px;
    line-height: 1.2;
    outline: none;
    font-size: 16px;
    width: 100%;

    pre, p pre, li pre, table pre {
        margin: 4px 2px 8px 2px;
        white-space: pre-wrap;
        font-size: 1em;
        font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
        padding: 8px;
        background-color: rgba(@primaryColorD, .03);
        code {
            display: block;
            color: @textColor;
            border: none;
        }
    }

    div.vz-codeblock-wrapper > pre {
        border-radius: 6px;
        box-shadow: 1px 1px 2px 1px rgba(@primaryColorD, .3);
    }

    li {
        position: relative;
    }

    ul {
        padding-left: 30px;
        &[data-type="taskList"] > li {
            display: flex;
            & > label {
                display: inline-flex;
                align-items: center;
                margin-right: 4px;
            }
        }
    }

    ol {
        padding-left: 30px;
    }

    blockquote {
        padding-left: 1em;
        border-left: 3px solid #eee;
        margin-left: 0;
        margin-right: 0;
    }

    p code, li code, table code {
        font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
        padding: 2px 4px;
        font-size: 85%;
        background-color: rgba(#f6f8fa, .8);
        border-radius: 6px;
        color: #a71d5d;
        border: 1px solid @borderColor;
        position: relative;
        top: -1px;
    }

    p {
        margin-bottom: 2px;
        &.is-editor-empty:first-child::before {
            color: #adb5bd;
            content: attr(data-placeholder);
            float: left;
            height: 0;
            pointer-events: none;
        }
        span[data-type="mention"] {
            vertical-align: top;
        }
    }

    span[data-type="emoji"] {
        margin: 0 2px;
        & > img {
            height: 20px;
            position: relative;
            top: -2px;
            margin-bottom: -2px;
        }
    }

    div.vz-codeblock-wrapper {
        position: relative;
        margin: 4px 0;
        a.vz-codeblock-lang {
            position: absolute;
            top: 2px;
            right: 10px;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background-color: rgba(#f6f8fa, .8);
            padding: 0 4px;
            &[data-text]::after {
                content: attr(data-text);
            }
            &:hover, &:active {
                text-decoration: none;
            }
            top: -10px;
            border-radius: 8px;
            border: 1px solid @borderColor;
            color: @primaryColorL;
        }
        span.vz-codeblock-lang {
            color: @textColorLL;
        }
}

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &:first-child {
            margin-top: 10px;
        }
    }
}

.ProseMirror-hideselection {
    * {
        &::selection {
            background: transparent;
        }

        &::-moz-selection {
            background: transparent;
        }
    }

    caret-color: transparent;
}

.ProseMirror-selectednode {
    outline: 2px solid #8cf;
}

li.ProseMirror-selectednode {
    outline: none;

    &:after {
        content: "";
        position: absolute;
        left: -32px;
        right: -2px;
        top: -2px;
        bottom: -2px;
        border: 2px solid #8cf;
        pointer-events: none;
    }
}

.ProseMirror-textblock-dropdown {
    min-width: 3em;
}

.ProseMirror-menu {
    margin: 0 -4px;
    line-height: 1;
}

.ProseMirror-tooltip {
    .ProseMirror-menu {
        width: -webkit-fit-content;
        width: fit-content;
        white-space: pre;
    }
}

.ProseMirror-menuitem {
    margin-right: 3px;
    display: inline-block;
}

.ProseMirror-menuseparator {
    border-right: 1px solid #ddd;
    margin-right: 3px;
}

.ProseMirror-menu-dropdown {
    font-size: 90%;
    white-space: nowrap;
    vertical-align: 1px;
    cursor: pointer;
    position: relative;
    padding-right: 15px;

    &:after {
        content: "";
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid currentColor;
        opacity: .6;
        position: absolute;
        right: 4px;
        top: calc(50% - 2px);
    }
}

.ProseMirror-menu-dropdown-menu {
    font-size: 90%;
    white-space: nowrap;
    position: absolute;
    background: white;
    color: #666;
    border: 1px solid #aaa;
    padding: 2px;
    z-index: 15;
    min-width: 6em;
}

.ProseMirror-menu-dropdown-wrap {
    padding: 1px 0 1px 4px;
    display: inline-block;
    position: relative;
}

.ProseMirror-menu-submenu {
    position: absolute;
    background: white;
    color: #666;
    border: 1px solid #aaa;
    padding: 2px;
    display: none;
    min-width: 4em;
    left: 100%;
    top: -3px;
}

.ProseMirror-menu-dropdown-item {
    cursor: pointer;
    padding: 2px 8px 2px 4px;

    &:hover {
        background: #f2f2f2;
    }
}

.ProseMirror-menu-submenu-wrap {
    position: relative;
    margin-right: -4px;

    &:hover {
        .ProseMirror-menu-submenu {
            display: block;
        }
    }
}

.ProseMirror-menu-submenu-label {
    &:after {
        content: "";
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid currentColor;
        opacity: .6;
        position: absolute;
        right: 4px;
        top: calc(50% - 4px);
    }
}

.ProseMirror-menu-active {
    background: #eee;
    border-radius: 4px;
    background: #eee;
    border-radius: 4px;
}

.ProseMirror-menu-disabled {
    opacity: .3;
}

.ProseMirror-menu-submenu-wrap-active {
    .ProseMirror-menu-submenu {
        display: block;
    }
}

.ProseMirror-menubar {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    position: relative;
    min-height: 1em;
    color: #666;
    padding: 1px 6px;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid silver;
    background: white;
    z-index: 10;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: visible;
}

.ProseMirror-icon {
    display: inline-block;
    line-height: .8;
    vertical-align: -2px;
    padding: 2px 8px;
    cursor: pointer;

    svg {
        fill: currentColor;
        height: 1em;
    }

    span {
        vertical-align: text-top;
    }
}

.ProseMirror-menu-disabled.ProseMirror-icon {
    cursor: default;
}

.ProseMirror-gapcursor {
    display: none;
    pointer-events: none;
    position: absolute;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: -2px;
        width: 20px;
        border-top: 1px solid black;
        animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
    }
}

.ProseMirror-focused {
    .ProseMirror-gapcursor {
        display: block;
    }
}

.ProseMirror-example-setup-style {
    hr {
        padding: 2px 10px;
        border: none;
        margin: 1em 0;

        &:after {
            content: "";
            display: block;
            height: 1px;
            background-color: silver;
            line-height: 2px;
        }
    }

    img {
        cursor: default;
    }
}

.ProseMirror-prompt {
    background: white;
    padding: 5px 10px 5px 15px;
    border: 1px solid silver;
    position: fixed;
    border-radius: 3px;
    z-index: 11;
    box-shadow: -.5px 2px 5px rgba(0, 0, 0, .2);

    h5 {
        margin: 0;
        font-weight: normal;
        font-size: 100%;
        color: #444;
    }

    input[type="text"] {
        background: #eee;
        border: none;
        outline: none;
        padding: 0 4px;
    }

    textarea {
        background: #eee;
        border: none;
        outline: none;
    }
}

.ProseMirror-prompt-close {
    position: absolute;
    left: 2px;
    top: 1px;
    color: #666;
    border: none;
    background: transparent;
    padding: 0;

    &:after {
        content: "âœ•";
        font-size: 12px;
    }
}

.ProseMirror-invalid {
    background: #ffc;
    border: 1px solid #cc7;
    border-radius: 4px;
    padding: 5px 10px;
    position: absolute;
    min-width: 10em;
}

.ProseMirror-prompt-buttons {
    margin-top: 5px;
    display: none;
}

/*
Visual Studio-like style based on original C# coloring by Jason Diamond <jason@diamond.name>
*/
.hljs {
    background: white;
    color: black;
}

.hljs-quote,
.hljs-variable,
.hljs-addition {
    color: #008000;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-built_in,
.hljs-name,
.hljs-tag {
    color: #00f;
}

.hljs-string,
.hljs-title,
.hljs-section,
.hljs-attribute,
.hljs-literal,
.hljs-template-tag,
.hljs-template-variable,
.hljs-type,
.hljs-deletion {
    color: #a31515;
}


.hljs-comment,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-meta {
    color: #2b91af;
}

.hljs-doctag {
    color: #808080;
}

.hljs-attr {
    color: #f00;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link {
    color: #00b0e8;
}


.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}

.vz-tpl-var {
    vertical-align: middle;
    border-radius: 6px;
    padding: 1px 2px;
    color: @okColorD;
    background: tint(@okColorL, 90%);
}

