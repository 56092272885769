.cmp-root {
    padding: 8px 0 8px 8px;
    &.cmp-root-mobile {
        padding: 8px;
        .vz-panel {
            .vz-panel-title {
                padding: 0 8px;
                max-width: 80%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
            }
        }
    }
    .cmp-content {
        padding-left: 8px;
    }
}
