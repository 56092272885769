.vz-dialog {
    z-index: 998;
    & > .ngx-dmd-container > .ngx-dmd-container-inner {
        border-top-color: @primaryColorD;
        & > .ngx-dmd-header {
            color: @primaryColor;
            & > .ngx-dmd-header-buttons > .ngx-dmd-header-button {
                background-color: @primaryColorD;
                &:hover, &:active {
                    background-color: tint(@primaryColorD, 15%);
                }
            }
        }
        & > .ngx-dmd-content {
            padding: 0;
            position: relative;
            display: flex;
            flex-direction: column;
        }
    }
}
