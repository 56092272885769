// @task-priority-symbol: "?", "1", "2", "3", "4", "5", "6", "7", "8", "9";
@task-priority-symbol: "?", "1", "2", "3";
.make-color-classes(@i: length(@task-priority-colors)) when (@i > 0) {
    .make-color-classes(@i - 1);
    @ii: @i - 1;
    @color: extract(@task-priority-colors, @i);
    @symbol: extract(@task-priority-symbol, @i);
    body{
        --vz-priorityColor@{ii}: @color;
    }
    .task-pr-@{ii} {
        color: @color;
    }
    .task-pr-bg-@{ii} {
        background-color: @color;
    }
    .task-flag-@{ii} {
        position: relative;
        line-height: 1;
        width: 19px;
        &::before, &::after {
            transition: margin @animation-duration-fast, text-shadow @animation-duration-fast;
        }
        &::after {
            position: absolute;
            content: "@{symbol}";
            color: #fff;
            font-size: 16px;
            top: 2px;
            left: 7px;
        }
        &::before {
            content: "\e939";
            color: @color;
            font-size: 24px;
            font-family: 'vizorro' !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        &:not(.task-flag-noshadow)::before {
            text-shadow: 2px 2px 3px @shadowColor;
        }
        &.task-flag-hover:hover {
            &::before, &::after {
                margin: -1px 1px 1px -1px;
                text-shadow: 3px 3px 4px @shadowColor;
            }
        }
        &.task-flag-fix::after {
            top: -6px;
        }
    }
    .task-pr-@{ii}-border {
        border-left: 4px solid @color;
    }
}

.make-color-classes();

[class^='task-flag-'], [class*=' task-flag-'] {
    &.task-flag-l {
        width: 22px;
        &::after {
            font-size: 19px;
            top: 2px;
            left: 9px;
        }
        &::before {
            font-size: 28px;
        }
    }
    &.task-flag-xl {
        width: 28px;
        &::after {
            font-size: 25px;
            top: 2px;
            left: 10px;
        }
        &::before {
            font-size: 34px;
        }
    }
    &.task-flag-s {
        width: 16px;
        &::after {
            font-size: 12px;
            top: 2px;
            left: 7px;
        }
        &::before {
            font-size: 20px;
        }
    }
}

.task-action {
    .task-action-buttons {
        visibility: hidden;
        position: absolute;
        background-color: @bgColor;
    }
    .task-action-button {
        visibility: hidden;
        position: absolute;
    }
    &:hover {
        .task-action-buttons {
            visibility: visible;
        }
        .task-action-button {
            visibility: visible;
        }
    }
    .task-action-time {
        color: @textColorL;
        margin-right: 8px;
        margin-left: 8px;
    }
}

.vz-avatar-is-group {
    border-radius: 7px;
    & > div {
        border-radius: 7px;
    }
}

.vz-avatar-is-user {
    border-radius: 50%;
    & > div {
        border-radius: 50%;
    }
}

vz-avatar-pair {
    border: 2px solid darken(@bgColor, 15%);
    .vz-avatar-pair {
        margin: 0 2px;
    }
    .vz-avatar-pair-left {
        background-color: darken(@bgColor, 25%);
    }
    &.vz-avatar-pair-left-user {
        .vz-avatar-pair-left {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }
    }
    &.vz-avatar-pair-right-user {
        .vz-avatar-pair-right {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }
    }
    &.vz-avatar-pair-left-group {
        .vz-avatar-pair-left {
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
        }
    }
    &.vz-avatar-pair-right-group {
        .vz-avatar-pair-right {
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
        }
    }
    .vz-avatar-pair-divider-wrapper {
        // background-color: @bgColor;
        .vz-avatar-pair-divider {
            width: 0;
            height: 0;
            border-left: solid darken(@bgColor, 25%);
            border-top: solid transparent;
            border-bottom: solid transparent;
        }
    }
    &.vz-avatar-pair-no-border {
        border: none;
    }
}

.vz-tl-related {
    color: @primaryColor;
}

.vz-tl-duplicate {
    color: @textColorL;
    font-size: 16px;
    margin: -1px -1px 0 0;
}

.vz-tl-blocks, .vz-tl-blocked {
    color: @errorColor;
    font-size: 20px;
    margin: -2px -2px 0 0;
}

.vz-task-archived {
    text-decoration: line-through dotted #666 !important;
}