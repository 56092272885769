.vz-btn-c, .vz-btn-c-err {
    cursor: pointer;
    color: @textColorL;
    transition: transform .3s;
    &:not(.selected) {
        &:hover, &:active {
            color: @accentColor;
        }
    }
    &.selected {
        color: @primaryColor;
    }
    &.disabled {
        color: @textColorLL;
        cursor: not-allowed;
        pointer-events: none;
    }
}
.vz-btn-c-err {
    &:not(.selected) {
        &:hover, &:active {
            color: rgba(@errorColor, .8);
        }
    }
    &.selected {
        color: @errorColor;
    }
}
.vz-btn-ch,
.vz-btn-chi {
    cursor: pointer;
    &:not(.selected) {
        &:hover, &:active {
            color: @warningColor;
        }
    }
    &.selected {
        color: @accentColor;
    }
    &.disabled {
        color: @textColorLL;
        cursor: not-allowed;
        pointer-events: none;
    }
}
.vz-btn-chi {
    &:not(.selected) {
        &:hover, &:active {
            color: @textColorL;
        }
    }
}

.vz-btn-c.txt-warn {
    color: @warningColor;
    &:not(.selected) {
        &:hover, &:active {
            color: @errorColor;
        }
    }
    &.selected {
        color: @errorColor;
    }
}

.vz-btn-xl,
.vz-btn-l,
.vz-btn,
.vz-btn-s,
.vz-btn-xs,
.vz-btn-xxs {
    background: rgba(0, 0, 0, 0);
    border-radius: 3px;
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
    opacity: 0.9;
    margin: 0;
    border: none;
    display: flex;
    overflow: hidden;
    &.vz-btn-bg {
        background: @bgColor;
        &:hover, &:focus {
            &:not(.disabled) {
                background: @bgColorDD;
            }
        }
    }
    &:hover, &:focus {
        &:not(.disabled) {
            opacity: 1;
            background: rgba(0, 0, 0, 0.1);
        }
    }
    &:active {
        &:not(.disabled) {
            opacity: 1;
            background: rgba(0, 0, 0, 0.2);
        }
    }
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        color: @textColorLL;
        pointer-events: none;
    }
    & > i {
        position: relative;
        top: -1px;
    }
    &.vz-btn-border-dotted {
        border: 1px dotted;
        & > i {
            position: inherit;
            top: auto;
        }
    }
}
.vz-btn-s {
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
    font-size: 20px;
    line-height: 1;
}
.vz-btn-xs {
    height: 25px;
    width: 25px;
    min-height: 25px;
    min-width: 25px;
    font-size: 16px;
    line-height: 1;
}
.vz-btn-xxs {
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    font-size: 12px;
    line-height: 1;
}
.vz-btn-l {
    height: 48px;
    width: 48px;
    min-height: 48px;
    min-width: 48px;
    font-size: 26px;
    line-height: 1;
}
.vz-btn-xl {
    height: 64px;
    width: 64px;
    min-height: 64px;
    min-width: 64px;
    font-size: 40px;
    line-height: 1;
}


.h-btn,
.h-btn-pl {
    background: rgba(0, 0, 0, 0);
    cursor: pointer;
    margin: 0;
    border: none;
    &:hover, &:focus {
        &:not(.disabled) {
            background-color: rgba(0, 0, 0, 0.07);
        }
    }
    &:active {
        &:not(.disabled) {
            margin: 1px -1px -1px 1px;
        }
    }
    & > i[class^="vzi-"],
    & > i[class*="vzi-"] {
        position: relative;
        padding: 0 3px;
    }
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
.h-btn-pl {
    &:hover, &:focus {
        &:not(.disabled) {
            background-color: rgba(@primaryColor, .1) !important;
        }
    }
}
.h2-btn,
.h2-btn-pl {
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
    border: none;
    &:not(.disabled) {
        &:hover, &:focus {
            background-color: rgba(0, 0, 0, 0.1);
        }
        &:active {
            background-color: rgba(0, 0, 0, 0.15);
        }
    }
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
.h2-btn-pl {
    background-color: rgba(@primaryColor, 95%);
    &:not(.disabled) {
        &:hover, &:focus {
            background-color: rgba(@primaryColor, 90%);
        }
        &:active {
            background-color: rgba(@primaryColor, 85%);
        }
    }
}
.h3-btn,
.h3-btn-pl {
    background: mix(@bgColor, @bgColorInv, 95%) !important;
    &:not(.disabled):not([disabled]) {
        cursor: pointer;
        &:hover, &:focus {
            background-color: mix(@bgColor, @bgColorInv, 90%) !important;
        }
        &:active {
            background-color: mix(@bgColor, @bgColorInv, 85%) !important;
        }
    }
}
.h3-btn-pl {
    background-color: mix(@bgColor, @primaryColor, .05) !important;
    &:not(.disabled):not([disabled]) {
        &:hover, &:focus {
            background-color: mix(@bgColor, @primaryColor, .1) !important;
        }
        &:active {
            background-color: mix(@bgColor, @primaryColor, .15) !important;
        }
    }
}

.tr-btn {
    cursor: pointer;
    opacity: 0.9;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    &:hover, &:focus {
        &:not(.disabled) {
            opacity: 1;
        }
    }
    &:active {
        &:not(.disabled) {
            margin: 1px -1px -1px 1px;
        }
    }
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.op-btn {
    cursor: pointer;
    opacity: .8;
    &:hover, &:focus {
        &:not(.disabled) {
            opacity: 1;
        }
    }
}

.shadow-btn {
    transition: box-shadow .3s;
    box-shadow: 0 0 transparent;
    &:hover, &:active {
        box-shadow: 0 0 5px 5px darken(@shadowColor, 15%);
    }
}

.btn-hover-container {
    .vz-btn,
    .vz-btn-c,
    .vz-btn-c-err,
    .vz-btn-xl,
    .vz-btn-l,
    .vz-btn,
    .vz-btn-s,
    .vz-btn-xs,
    .vz-btn-xxs {
        opacity: 0;
        transition: opacity .3s;
    }
    &:hover{
        .vz-btn,
        .vz-btn-c,
        .vz-btn-c-err,
        .vz-btn-xl,
        .vz-btn-l,
        .vz-btn,
        .vz-btn-s,
        .vz-btn-xs,
        .vz-btn-xxs {
            opacity: 1;
            transition: opacity .3s;
        }
    }
}
