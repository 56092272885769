.file-upload-drop-box {
    padding: 4px 8px;
    border: 2px dashed @borderColor;
    border-radius: 8px;
    cursor: pointer;
    min-height: 3rem;
    &.fileDrop-file-over {
        border-color: @okColor !important;
        background-color: rgba(@okColor, .2);
        & > span {
            color: @okColor;
        }
    }
    & > span {
        margin: 8px;
        font-weight: bold;
        color: @textColorL;
    }
    &.fileDrop-empty {
        border-color: rgba(@primaryColor, .5);
        & > span {
            color: @primaryColorL;
        }
    }
    .file-upload-preview {
        width: 110px;
        height: 102px;
        min-width: 110px;
        min-height: 102px;
        border: 1px solid @borderColor;
        border-radius: 4px;
        background-color: @bgColor;
        overflow: hidden;
        position: relative;
        padding: 2px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

&.fileDrop-file-over {
    & > .fileDrop-overlay {
        opacity: 1;
        transition: opacity .3s;
        &::after {
            box-shadow: inset 0 0 2000px 2000px rgba(@okColor, .1);
            border: 2px dashed @okColorD;
            z-index: 1;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}

.fileDrop-overlay {
    pointer-events: none;
    opacity: 0;
    z-index: 1;
    margin: 2px;
    font-size: 40px;
    transition: opacity .3s;
}

.vz-file-dropzone-h {
    border: 2px dashed @borderColor;
    transition: color .2s, border-color .2s, padding-top .2s .1s, padding-bottom .2s .1s, font-size .2s .1s;
    padding: 8px 16px;
    font-size: 16px;
    color: @textColorL;
    position: relative;
    &::after {
        transition: box-shadow .2s;
    }
}
.vz-file-over {
    .vz-file-dropzone-h {
        border-color: rgba(@accentColor, .4);
        color: @primaryColor;
        padding-top: 24px;
        padding-bottom: 24px;
        font-size: 22px;
        &::after {
            box-shadow: inset 0 0 2000px 2000px rgba(@accentColor, .1);
            border-color: rgba(@accentColor, .7);
            z-index: 1;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        &.fileDrop-file-over {
            color: @okColorD;
            border-color: @okColor;
            &::after {
                box-shadow: inset 0 0 2000px 2000px rgba(@okColor, .1);
                border-color: @okColor;
            }
        }
    }
}

.vz-file-dropzone-inv {
    pointer-events: none;
    opacity: 0;
    z-index: 1;
    margin: 2px;
    font-size: 44px;
    transition: color .2s, opacity .2s;
    overflow: hidden;
    &::after {
        border: 2px dashed @borderColor;
        transition: border-color .2s, box-shadow .2s;
    }
}
.vz-file-over {
    .vz-file-dropzone-inv {
        opacity: .8;
        color: @primaryColor;
        &::after {
            box-shadow: inset 0 0 2000px 2000px rgba(@accentColor, .2);
            border-color: rgba(@accentColor, .7);
            z-index: 1;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
    .fileDrop-file-over > .vz-file-dropzone-inv {
        opacity: 1;
        color: @okColorD;
        &::after {
            box-shadow: inset 0 0 2000px 2000px rgba(@okColor, .2);
            border-color: @okColor;
        }
    }
}
