@import "../../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import '@angular/cdk/overlay-prebuilt.css';

@import './theme';
@import './ant-overrides';
@import './pdf-viewer-overrides';
@import './editor';
@import './layouts';
@import './colors';
@import './loader';
@import './panels';
@import './formatting';
@import './tables';
@import './text';
@import './fonts';
@import './icons';
@import './helpers';
@import './buttons';
@import './uploads';
@import './tasks';
@import './badges';
@import './dialogs';
@import './chats';
@import './charts';
@import './dividers';
@import './highlights';
@import './trees';
@import './animations';
@import './resize';
@import './progress';
@import './components';
