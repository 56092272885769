.nt-error {
    border: 1px solid tint(@errorColor, 70%);
    box-shadow: @shadow-2, inset 0 0 50px 0 rgba(@errorColor, 0.12);
    width: calc(min(100%, 608px) - 8px);
    & .ant-notification-notice-message {
        color: @errorColor;
    }
}

.nt-warn {
    border: 1px solid tint(@warningColor, 70%);
    box-shadow: @shadow-2, inset 0 0 50px 0 rgba(@warningColor, 0.12);
    width: calc(min(100%, 608px) - 8px);
    & .ant-notification-notice-message {
        color: @warningColor;
    }
}

.nt-info {
    border: 1px solid tint(@infoColor, 70%);
    box-shadow: @shadow-2, inset 0 0 50px 0 rgba(@infoColor, 0.12);
    width: calc(min(100%, 608px) - 8px);
    & .ant-notification-notice-message {
        color: @infoColor;
    }
}

.nt-ok {
    border: 1px solid tint(@okColor, 70%);
    box-shadow: @shadow-2, inset 0 0 50px 0 rgba(@okColor, 0.12);
    width: calc(min(100%, 608px) - 8px);
    & .ant-notification-notice-message {
        color: @okColor;
    }
}

.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    &.vz-ok-btn:not([disabled]) {
        border-color: darken(@okColor, 10%);
        &:hover {
            border-color: darken(@okColor, 5%);
        }
        &.ant-btn-primary {
            background-color: darken(@okColor, 10%);
            &:hover {
                background-color: darken(@okColor, 5%);
            }
        }
        &:not(.ant-btn-primary) {
            color: darken(@okColor, 10%);
            &:hover {
                color: darken(@okColor, 5%);
            }
        }
        &.vz-ok-btn-inv {
            background-color: darken(@okColor, 10%);
            color: @textColorInv;
            &:hover {
                background-color: darken(@okColor, 5%);
                color: @textColorInv;
            }
        }
    }
    &.vz-err-btn:not([disabled]) {
        border-color: darken(@errorColor, 10%);
        &:hover {
            border-color: darken(@errorColor, 5%);
        }
        &.ant-btn-primary {
            background-color: darken(@errorColor, 10%);
            &:hover {
                background-color: darken(@errorColor, 5%);
            }
        }
        &:not(.ant-btn-primary) {
            color: darken(@errorColor, 10%);
            &:hover {
                color: darken(@errorColor, 5%);
            }
        }
        &.vz-err-btn-inv {
            background-color: darken(@errorColor, 10%);
            color: @textColorInv;
            &:hover {
                background-color: darken(@errorColor, 5%);
                color: @textColorInv;
            }
        }
    }
    &:not(.ant-btn-icon-only) {
        & > [class^="vzi-"],
        & > [class*=" vzi-"] {
            margin-right: 8px;
        }
    }
    &.ant-btn-right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
        width: 33px;
        z-index: 0;
        &:hover {
            z-index: 1;
        }
    }
    &.ant-btn-left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-left: -1px;
        width: 33px;
        z-index: 0;
        &:hover {
            z-index: 1;
        }
    }
    &.ant-btn-dangerous.ant-btn-primary[disabled] {
        border-color: #d96969;
        background: #f5d5d5;
        color: #b09090;
    }
}

*:has(+ button.ant-btn.ant-btn-right) {
    z-index: 1;
}

.ant-dropdown {
    max-width: min(1024px, 100%);
    & > .ant-dropdown-menu > .vz-menu-title {
        text-align: center;
        border-bottom: 1px solid @shadowColor !important;
        background-color: @bgColor !important;
        box-shadow: inset 0 10px 33px rgba(@bgColorInv, .1);
        margin-top: -4px;
        color: @textColorL;
        font-size: 12px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        display: flex;
        padding: 4px 12px;
        font-size: 16px;
    }
}

.ant-drawer-body {
    position: relative;
}

.vz-drawer-allow-overflow .ant-drawer-body {
    overflow: visible;
}

.ant-dropdown-menu-item > .ant-menu-title-content {
    display: flex;
    flex: 1;
    flex-basis: 0px;
    align-items: center;
    & > [class^="vzi-"],
    & > [class*=" vzi-"] {
        font-size: 20px;
        padding-right: 8px;
        min-width: 32px;
    }
}

.ant-modal-confirm-btns {
    display: flex;
}

.ant-drawer-content-wrapper {
    &.no-transition {
        transition: none;
    }
    &.ant-drawer-danger {
        .ant-drawer-content {
            background-color: #ff8;
            .ant-drawer-header {
                background-color: #600;
                border-bottom: 2px solid #ff0;
                .ant-drawer-title {
                    color: #ff0;
                    font-weight: bold;
                }
                button.ant-drawer-close {
                    color: #ff0;
                }
            }
        }
    }
    &.context-menu {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;
        & > ant-drawer-content {

        }
    }
}

nz-form-control.flex > div.ant-form-item-control-input {
    flex: 1;
    flex-basis: 0px;
}

.ant-popover-buttons {
    display: flex;
}

a:active, a:focus, a:hover {
    text-decoration: underline;
}

nz-tabset.nz-tabset-flex {
    &.ant-tabs-top > .ant-tabs-nav {
        margin: 0 8px;
        .ant-tabs-tab {
            padding: 0 4px 4px 4px;
        }
        &::before {
            display: none;
        }
    }
    &.ant-tabs-card > .ant-tabs-nav {
        margin-left: -1px;
        .ant-tabs-tab {
            padding: 8px 16px;
        }
    }
    & > .ant-tabs-content-holder {
        display: flex;
        flex-direction: column;
        .ant-tabs-content {
            height: 120px;
            display: flex;
            flex: auto;
            width: auto;
            & > .ant-tabs-tabpane {
                background: #fff;
                display: flex;
                flex: auto;
                flex-direction: column;
                position: relative;
            }
        }
        .ant-tabs-tab-active {
            border-color: #fff;
            background: #fff;
        }
    }
    .ant-tabs-tab.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            font-weight: 400;
        }
    }
    &.nz-tabset-padding-8 > .ant-tabs-content-holder .ant-tabs-content > .ant-tabs-tabpane {
        padding: 8px;
    }
    &.nz-tabset-padding-16 > .ant-tabs-content-holder .ant-tabs-content > .ant-tabs-tabpane {
        padding: 16px;
    }
}

.ant-menu-vertical.ant-menu-root .ant-menu-item > .ant-menu-title-content > span, .ant-menu-vertical.ant-menu-root .ant-menu-submenu-title > span  {
    flex: 1;
}

.ant-menu-title-content {
    display: flex;
    align-items: center;
}

.ant-menu-submenu .ant-menu-vertical.ant-menu-sub {
    min-width: 220px;
}

.ant-menu.ant-menu-vertical-collapsed  .ant-menu-item {
    padding: 0 10px;
}

.app-menu-mobile .ant-menu-vertical {
    .ant-menu-item {
        &.ant-menu-item-selected {
            border-left: 3px solid @primaryColor;
            padding-left: 14px;
        }
        &::after {
            border-right: none;
        }
    }
    .ant-menu-submenu.ant-menu-submenu-vertical .ant-menu-submenu-title .ant-menu-submenu-arrow {
        right: auto;
        left: 4px;
        transform: rotate(180deg);
    }
}

.ant-dropdown-menu-submenu.vz-submenu-correct > div.ant-dropdown-menu-submenu-title > .ant-dropdown-menu-submenu-expand-icon {
    top: 8px;
}

.ant-checkbox-wrapper {
    align-items: center;
    &:not(.start) .ant-checkbox {
        top: 0;
    }
    &.ant-checkbox-danger {
        color: @cbDanger;
        .ant-checkbox {
            .ant-checkbox-inner {
                border-color: @cbDanger;
            }
            &.ant-checkbox-indeterminate .ant-checkbox-inner {
                background-color: @bgColor;
                border-color: @cbDanger;
                &::after {
                    background-color: @cbDanger;
                }
            }
            &.ant-checkbox-checked .ant-checkbox-inner {
                background-color: @cbDanger;
                border-color: @cbDanger;
            }
        }
    }
    &.ant-checkbox-org {
        color: @cbOrg;
        .ant-checkbox {
            .ant-checkbox-inner {
                border-color: @cbOrg;
            }
            &.ant-checkbox-indeterminate .ant-checkbox-inner {
                background-color: @bgColor;
                border-color: @cbOrg;
                &::after {
                    background-color: @cbOrg;
                }
            }
            &.ant-checkbox-checked .ant-checkbox-inner {
                background-color: @cbOrg;
                border-color: @cbOrg;
            }
        }
    }
    &.ant-checkbox-orgnadpr {
        color: @cbOaP;
        .ant-checkbox {
            .ant-checkbox-inner {
                border-color: @cbOaP;
            }
            &.ant-checkbox-indeterminate .ant-checkbox-inner {
                background-color: @bgColor;
                border-color: @cbOaP;
                &::after {
                    background-color: @cbOaP;
                }
            }
            &.ant-checkbox-checked .ant-checkbox-inner {
                background-color: @cbOaP;
                border-color: @cbOaP;
            }
        }
    }
    &.ant-checkbox-project {
        color: @cbPrj;
        .ant-checkbox {
            .ant-checkbox-inner {
                border-color: @cbPrj;
            }
            &.ant-checkbox-indeterminate .ant-checkbox-inner {
                background-color: @bgColor;
                border-color: @cbPrj;
                &::after {
                    background-color: @cbPrj;
                }
            }
            &.ant-checkbox-checked .ant-checkbox-inner {
                background-color: @cbPrj;
                border-color: @cbPrj;
            }
        }
    }
}


nz-input-group {
    & > .ant-input-prefix {
        color: @textColorL;
    }
    &.ant-group-hl {
        border-color: @primaryColorL;
        box-shadow: inset 0 0 2px 3px rgba(@primaryColorL, .1);
        & > input, .ant-input-prefix {
            color: @primaryColorL;
        }
    }
}

.ant-tooltip {
    max-width: 840px;
    &.ant-tt-p2 > .ant-tooltip-content > .ant-tooltip-inner {
        padding: 2px;
    }
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
    pointer-events: none;
}

.ant-image-preview-mask {
    background-color: rgba(@bgColorInv, .85);
}

.vz-tooltip-light {
    & > .ant-tooltip-content {
        border-radius: 4px;
        .ant-tooltip-arrow-content {
            --antd-arrow-background-color: #fff;
        }
        & > .ant-tooltip-inner {
            background-color: @bgColor;
            border: 1px solid @borderColor;
            border-radius: 4px;
        }
    }
}

.ant-menu-inline .ant-menu-submenu-title {
    padding-right: 16px;
}

.vz-nz-select-border .ant-select-dropdown {
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: @bgColor;
        box-shadow: inset rgba(@primaryColor, .7) 0 0 5px 1px;
    }
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked) {
    background-color: @bgColor !important;
}

.vz-sidemenu.ant-menu.ant-menu-dark {
    padding-top: 8px;
    background-color: var(--vz-activeOrgBg, @primaryColorD);
    & > a {
        text-decoration: none;
        &:hover, &:active {
            text-decoration: none;
        }
    }
    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: var(--vz-activeOrgBgLL, tint(@primaryColorD, 20%));
    }
    &.ant-menu-root.ant-menu-inline {
        user-select: none;
        .ant-menu-item {
            margin: 0;
        }
        &:not(.ant-menu-inline-collapsed) {
            .ant-menu-item {
                height: 30px;
                padding-left: 10px !important;
                &.ant-menu-item-selected {
                    background-color: var(--vz-activeOrgBgLL, tint(@primaryColorD, 20%));
                }
                .ant-menu-title-content {
                    & > i {
                        font-size: 20px;
                        min-width: 30px;
                    }
                }
            }
        }
    }
    .app-menu-chat-head {
        .app-menu-chat-head-buttons {
            margin-right: -18px;
        }
    }
    .app-menu-chat {
        color: @textColor;
        margin-bottom: 0;
        height: 22px !important;
        font-size: 12px;
        line-height: 14px;
        i {
            min-width: 50px;
            color: @textColorL;
            font-size: 14px;
        }
        .app-menu-chat-title {
            display: grid;
            width: 100%;
            & > span {
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 2px;
            }
        }
        .app-menu-counter-wrapper {
            font-size: 11px;
            line-height: 15px;
            padding-right: 16px;
            .app-menu-counter-single {
                padding: 0 4px;
                color: @textColorInv;
                background-color: var(--vz-activeOrgBgLL, tint(@primaryColorD, 20%));
                border-color: var(--vz-activeOrgBgLL, tint(@primaryColorD, 20%));
            }
        }
        i {
            color: @textColorLL;
        }
    }
    .app-menu-counter-wrapper {
        margin-left: 4px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        line-height: 18px;
        background-color: transparent;
        color: @textColorInv;
        border-radius: 9px;
        .app-menu-counter-left,
        .app-menu-counter-right,
        .app-menu-counter-single {
            border-radius: 9px;
            padding: 0 5px;
            min-width: 15px;
            color: @textColorInv;
            border: 1px solid rgba(@bgColor, .3);
            &.app-menu-counter-mentions:not(.app-menu-counter-muted) {
                background-color: @errorColorD;
                border-color: @errorColorD;
            }
            &.app-menu-counter-muted {
                background-color: transparent;
                border-color: rgba(@bgColor, .5);
                color: @bgColorDD;
            }
        }
        .app-menu-counter-left {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 1px dotted @primaryColor;
            border-right-color: @okColorD;
            background-color: @okColorD;
        }
        .app-menu-counter-right,
        .app-menu-counter-single {
            border: 1px solid @textColorLL;
            background-color: var(--vz-activeOrgBgLL, tint(@primaryColorD, 20%));
        }
        .app-menu-counter-right {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    &.ant-menu-inline-collapsed,
    .ant-menu-submenu-inline {
        .ant-menu-submenu-title {
            height: 30px;
            line-height: 30px;
            padding-left: 10px !important;
        }
        .ant-menu-submenu-arrow {
            transform: translateY(-2px);
            &::before {
                left: -8px;
                transform: rotate(45deg) translateX(2.5px);
            }
            &::after {
                left: -8px;
                transform: rotate(135deg) translateX(2.5px);
            }
        }
        &.ant-menu-dark {
            background-color: var(--vz-activeOrgBg, @primaryColorD);
        }
    }
    .ant-menu-submenu-inline {
        & > .ant-menu-submenu-title {
            & > .ant-menu-title-content {
                display: none;
            }
        }
        &.ant-menu-submenu-open {
            & > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
                transform: translateY(0);
                &::before {
                    transform: rotate(-45deg) translateX(2.5px);
                }
                &::after {
                    transform: rotate(215deg) translateX(2.5px);
                }
            }
        }
    }
    .ant-menu-sub.ant-menu-inline {
        background-color: transparent;
    }
    .ant-menu-inline.ant-menu-root .ant-menu-item > span {
        flex: 1;
    }
    .ant-menu-submenu-arrow {
        right: auto;
        left: 14px;
    }
    .ant-dropdown-menu-item-divider, .ant-dropdown-menu-submenu-title-divider {
        background-color: rgba(@textColorLL, .3);
    }
    .ant-menu-item {
        position: relative;
    }
    .ant-menu-item, .app-menu-chat {
        color: @textColorInvD;
        &:hover {
            color: @textColorInv;
        }
    }
    i {
        font-size: 28px;
        min-width: 40px;
        text-align: center;
        &.vzi-completed {
            font-size: 22px;
        }
    }

    &.vz-sidemenu-collapsed {
        width: 70px;
        .app-menu-counter-wrapper {
            font-size: 12px;
            line-height: 1.4;
            position: absolute;
            top: 0;
            right: 8px;
            & > div {
                padding: 0 3px;
                min-width: 12px;
            }
        }
        .ant-menu-item {
            padding: 0 14px;
        }
        .ant-menu-submenu {
            padding: 0 3px;
            color: @textColorInvD;
        }
        a > li.ant-menu-item {
            padding: 0 19px;
        }
    }
}

