.layout {
    display: flex;

    &.horizontal {
        flex-direction: row;
    }

    &.vertical {
        flex-direction: column;
    }

    &.center,
    &.center-center {
        align-items: center;
    }

    &.center-justified,
    &.center-center {
        justify-content: center;
    }

    &.inline {
        display: inline-flex;
    }

    &.wrap {
        flex-wrap: wrap;
    }

    &.horizontal-reverse {
        flex-direction: row-reverse;
    }

    &.vertical-reverse {
        flex-direction: column-reverse;
    }

    &.wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    &.start {
        align-items: flex-start;
    }

    &.end {
        align-items: flex-end;
    }

    &.baseline {
        align-items: baseline;
    }

    &.stretch {
        align-items: stretch;
    }

    &.start-justified {
        justify-content: flex-start;
    }

    &.center-justified,
    &.center-center {
        justify-content: center;
    }

    &.end-justified {
        justify-content: flex-end;
    }

    &.around-justified {
        justify-content: space-around;
    }

    &.justified {
        justify-content: space-between;
    }

    &.start-aligned {
        align-content: flex-start;
    }

    &.end-aligned {
        align-content: flex-end;
    }

    &.center-aligned {
        align-content: center;
    }

    &.between-aligned {
        align-content: space-between;
    }

    &.around-aligned {
        align-content: space-around;
    }

    &.gap01 { gap: 1px; }
    &.gap025 { gap: 4px; }
    &.gap05 { gap: 8px; }
    &.gap1 { gap: 16px; }
    &.gap2 { gap: 32px; }

    &.gh01 { column-gap: 1px; }
    &.gh025 { column-gap: 4px; }
    &.gh05 { column-gap: 8px; }
    &.gh1 { column-gap: 16px; }
    &.gh2 { column-gap: 32px; }

    &.gv01 { row-gap: 1px; }
    &.gv025 { row-gap: 4px; }
    &.gv05 { row-gap: 8px; }
    &.gv1 { row-gap: 16px; }
    &.gv2 { row-gap: 32px; }

}

.flex {
    flex: 1;
    flex-basis: 0.000000001px;
}

.fit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.flex-auto {
    flex: 1 1 auto;
}

.flex-none {
    flex: none;
}

.self-start {
    align-self: flex-start;
}

.self-center {
    align-self: center;
}

.self-end {
    align-self: flex-end;
}

.self-stretch {
    align-self: stretch;
}

.self-baseline {
    align-self: baseline;
}

.flex,
.flex-1 {
    flex: 1;
    flex-basis: 0.000000001px;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.flex-6 {
    flex: 6;
}

.flex-7 {
    flex: 7;
}

.flex-8 {
    flex: 8;
}

.flex-9 {
    flex: 9;
}

.flex-10 {
    flex: 10;
}

.flex-11 {
    flex: 11;
}

.flex-12 {
    flex: 12;
}

.block {
    display: block;
}

[hidden] {
    display: none !important;
}

.invisible {
    visibility: hidden !important;
}

.relative {
    position: relative;
}
.f-relative {
    position: relative !important;
}

.scroll {
    overflow: auto;
}

.scroll-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.scroll-y {
    overflow-y: auto;
    overflow-x: hidden;
}

.fixed.fit,
.fixed-bottom,
.fixed-left,
.fixed-right {
    position: fixed;
}

.fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.fixed-right {
    top: 0;
    right: 0;
    bottom: 0;
}

.fixed-bottom {
    right: 0;
    bottom: 0;
    left: 0;
}

.fixed-left {
    top: 0;
    bottom: 0;
    left: 0;
}

.fs0 {
    flex-shrink: 0;
}

.spaced025 > {
    & > * {
        margin: 4px;
    }
}

.spaced025h > {
    & > *:not(:first-child) {
        margin-left: 4px;
    }
    & > *:not(:last-child) {
        margin-right: 4px;
    }
}

.spaced05h > {
    & > *:not(:first-child) {
        margin-left: 8px;
    }
    & > *:not(:last-child) {
        margin-right: 8px;
    }
}
