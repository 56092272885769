.ml-1 { margin-left: -1px !important; }
.ml-025 { margin-left: -0.25rem !important; }
.ml-05 { margin-left: -0.5rem !important; }
.ml-1 { margin-left: -1rem !important; }
.ml-2 { margin-left: -2rem !important; }
.ml0 { margin-left: 0 !important; }
.ml025 { margin-left: 0.25rem !important; }
.ml05 { margin-left: 0.5rem !important; }
.ml1 { margin-left: 1rem !important; }
.ml2 { margin-left: 2rem !important; }

.mr-1 { margin-right: -1px !important; }
.mr-025 { margin-right: -0.25rem !important; }
.mr-05 { margin-right: -0.5rem !important; }
.mr-1 { margin-right: -1rem !important; }
.mr-2 { margin-right: -2rem !important; }
.mr0 { margin-right: 0 !important; }
.mr025 { margin-right: 0.25rem !important; }
.mr05 { margin-right: 0.5rem !important; }
.mr1 { margin-right: 1rem !important; }
.mr2 { margin-right: 2rem !important; }

.mt-1p { margin-top: -1px !important; }
.mt-2p { margin-top: -2px !important; }
.mt-025 { margin-top: -0.25rem !important; }
.mt-05 { margin-top: -0.5rem !important; }
.mt-1 { margin-top: -1rem !important; }
.mt-2 { margin-top: -2rem !important; }
.mt0 { margin-top: 0 !important; }
.mt025 { margin-top: 0.25rem !important; }
.mt05 { margin-top: 0.5rem !important; }
.mt1 { margin-top: 1rem !important; }
.mt2 { margin-top: 2rem !important; }

.mb-1 { margin-bottom: -1px !important; }
.mb-025 { margin-bottom: -0.25rem !important; }
.mb-05 { margin-bottom: -0.5rem !important; }
.mb-1 { margin-bottom: -1rem !important; }
.mb-2 { margin-bottom: -2rem !important; }
.mb0 { margin-bottom: 0 !important; }
.mb025 { margin-bottom: 0.25rem !important; }
.mb05 { margin-bottom: 0.5rem !important; }
.mb1 { margin-bottom: 1rem !important; }
.mb2 { margin-bottom: 2rem !important; }

.mh0 { margin-left: 0 !important; margin-right: 0 !important; }
.mh025 { margin-left: 0.25rem !important; margin-right: 0.25rem !important; }
.mh05 { margin-left: 0.5rem !important; margin-right: 0.5rem !important; }
.mh1 { margin-left: 1rem !important; margin-right: 1rem !important; }
.mh2 { margin-left: 2rem !important; margin-right: 2rem !important; }

.mv0 { margin-top: 0 !important; margin-bottom: 0 !important; }
.mv025 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
.mv05 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
.mv1 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
.mv2 { margin-top: 2rem !important; margin-bottom: 2rem !important; }

.m0 { margin: 0 !important; margin: 0 !important; }
.m025 { margin: 0.25rem !important; margin: 0.25rem !important; }
.m05 { margin: 0.5rem !important; margin: 0.5rem !important; }
.m1 { margin: 1rem !important; margin: 1rem !important; }
.m2 { margin: 2rem !important; margin: 2rem !important; }

.tal { text-align: left !important; }
.tar { text-align: right !important; }
.tac { text-align: center !important; }

.fw-b { font-weight: bold !important; }
.fw-n { font-weight: normal !important; }

.fd-n { font-style: normal !important; }
.fd-i { font-style: italic !important; }
.fd-u { text-decoration: underline !important; }
.fd-lt { text-decoration: line-through !important; }

.vat { vertical-align: top !important; }
.vam { vertical-align: middle !important; }
.vab { vertical-align: bottom !important; }

.lh12 { line-height: 1.2 !important; }
.lh11 { line-height: 1.1 !important; }
.lh1 { line-height: 1 !important; }
.lh09 { line-height: 0.9 !important; }
.lh08 { line-height: 0.8 !important; }

.top0, .top1, .top2, .top3, .top-1, .top-2, .top-3 { position: relative; }
.top0 { top: 0; }
.top1 { top: 1px; }
.top2 { top: 2px; }
.top3 { top: 3px; }
.top-1 { top: -1px; }
.top-2 { top: -2px; }
.top-3 { top: -3px; }

.p0 { padding: 0; }
.p025 { padding: .25rem; }
.p05 { padding: .5rem; }
.p1 { padding: 1rem; }

.pv0 { padding-top: 0; padding-bottom: 0; }
.pv025 { padding-top: .25rem; padding-bottom: .25rem; }
.pv05 { padding-top: .5rem; padding-bottom: .5rem; }
.pv1 { padding-top: 1rem; padding-bottom: 1rem; }

.ph0 { padding-left: 0; padding-right: 0; }
.ph025 { padding-left: .25rem; padding-right: .25rem; }
.ph05 { padding-left: .5rem; padding-right: .5rem; }
.ph1 { padding-left: 1rem; padding-right: 1rem; }
