.ngx-dm-divider {
    &.ngx-dm-divider-hor {
        cursor: ew-resize;
        width: 5px;
        margin: 0;
        border: none;
        background-color: transparent;
        transition: background-color .3s cubic-bezier(0.19, 1, 0.22, 1) .3s;
        &:hover, &:active {
            background-color: @primaryColorL;
        }
    }
    &.ngx-dm-divider-vert {
        cursor: ns-resize;
        height: 5px;
        margin: 0;
        border: none;
        background-color: transparent;
        transition: background-color .3s cubic-bezier(0.19, 1, 0.22, 1) .3s;
        &:hover, &:active {
            background-color: @primaryColorL;
        }
    }
}

