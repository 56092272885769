table.vz-table {
    border-collapse: separate;
    border-spacing: 0;
    & > thead > tr,
    & > tbody > tr {
        &.vz-selected {
            box-shadow: inset rgba(@primaryColorL, .15) 0 0 100px 0;
        }
        & > td,
        & > th {
            text-align: left;
            border-width: 1px 0 1px 0;
            border-color: transparent;
            border-style: solid;
            padding: 3px 5px;
            &.vz-cell-default {
                color: @textColorLL;
            }
        }
    }
    & > thead > tr {
        & > td,
        & > th {
            text-align: left;
            border-width: 0 1px 1px 0;
            border-color: @border-color-base;
            border-style: solid;
            border-right-style: dashed;
            padding: 3px 5px;
            font-weight: bold;
            text-align: center;
            &:last-child {
                border-right: 0;
            }
        }
    }
    & > tbody > tr {
        & > td,
        & > th {
            &:not(:last-child) {
                border-right: 1px dashed @border-color-split;
            }
        }
    }
    &.vz-table-stripes > tbody > tr:nth-child(even) {
        background-color: @bgColorD;
    }
    &.vz-table-key-value {
        border-radius: 6px;
        border: 1px solid @borderColor;
        padding: 2px;
        & > tbody > tr > td:not(:first-child) {
            color: @primaryColorD;
        }
    }
    &.vz-table-clickable > tbody > tr > td {
        cursor: pointer;
    }
    &.vz-table-nowrap {
        & > tbody > tr > td {
            white-space: nowrap;
        }
    }
    &.vz-table-rows-hover {
        & tbody > tr {
            &:hover {
                box-shadow: inset rgba(@primaryColor, .7) 0 0 3px 0;
            }
        }
    }
    &.vz-table-cross {
        margin: 5px;
        min-width: auto;
        z-index: 1;
        overflow: hidden;
        & > thead > tr  {
            & > td, & > th {
                color: @primaryColor;
                font-weight: bold;
                text-align: center;
                padding: 3px 10px;
                position: relative;
            }
            &:last-child > td, &:last-child > th {
                border-bottom: 2px solid rgba(@primaryColor, 0.3);
            }
        }
        & > tbody > tr > td {
            padding: 3px 10px;
            font-weight: normal;
            position: relative;
            &:not(:first-child) {
                border-left: 1px solid rgba(@primaryColor, 0.1) !important;
            }
        }
        &.vz-table-cross-always {
            & > tbody > tr > td {
                border-color: rgba(@primaryColor, 0.3);
                border-top: none;
            }
        }

        &.vz-table-cross-hover  {
            & > thead > tr:hover > td,
            & > thead > tr:hover > th {
                border-color: transparent;
                border-bottom-color: rgba(@primaryColor, 0.3);
            }
            & > thead > tr > td:hover::after,
            & > thead > tr > th:hover::after,
            & > tbody > tr > td:hover::after {
                border-left: 1px solid rgba(@primaryColor, 0.3);
                border-right: 1px solid rgba(@primaryColor, 0.3);
                content: '\00a0';
                height: 10000px;
                left: 0;
                position: absolute;
                top: -5000px;
                width: 100%;
                z-index: 1;
                pointer-events: none;
            }
        }
    }
    & > thead > tr > th,
    & > thead > tr > td,
    & > tbody > tr > td {
        &.vz-list-selector {
            & > .ant-checkbox-wrapper {
                display: none;
            }
            & > .vz-list-icon {
                display: inline-block;
                color: @primaryColorD;
                position: relative;
                top: 3px;
                font-size: 1.5rem;
                line-height: .8;
            }
            &:hover, &.vz-list-selecting {
                & > .ant-checkbox-wrapper {
                    display: inline-block;
                }
                & > .vz-list-icon {
                    display: none;
                }
            }
        }
    }
    & > tbody > tr > td {
        &.vz-list-selector {
            height: 35px;
        }
    }
    &.vz-table-compact {
        & > thead > tr > th,
        & > thead > tr > td,
        & > tbody > tr > td {
            &.vz-list-selector {
                & > .ant-checkbox-wrapper {
                    display: none;
                }
                & > .vz-list-icon {
                    display: inline-block;
                }
                &:hover, &.vz-list-selecting {
                    & > .ant-checkbox-wrapper {
                        display: none;
                    }
                    & > .vz-list-icon {
                        display: inline-block;
                    }
                }
            }
        }
    }
    &.vz-table-params {
        & > tr > td {
            &:first-child {
                text-align: right;
                white-space: nowrap;
                padding-right: 4px;
            }
            &:not(:first-child) {
                font-weight: bold;
                width: 100%;
            }
        }
    }
}

dm-table {
    table.ngx-dmt {
        & > thead > tr.ngx-dmt-header-row {
            z-index: 1;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 0;
                transition: all .3s;
                pointer-events: none;
            }
            & > th.ngx-dmt-header-cell {
                &.ngx-dmt-header-cell-select {
                    cursor: pointer;
                }
                &.th-name {
                    border-right: 2px solid @primaryColor !important;
                }
                & > .ngx-dmt-column-sort-container {
                    & > .ngx-dmt-column-sort-toggle {
                        color: @primaryColorL;
                    }
                    & > .ngx-dmt-column-sort-count {
                        color: @textColorL;
                    }
                }
            }
        }
        & > tbody {
            & > tr.ngx-dmt-group-header-row {
                background-color: @bgColor;
                & > td.ngx-dmt-group-header-cell {
                    padding: 10px 10px 5px 10px;
                    border-bottom: 1px solid @borderColor;
                }
            }

            & > tr.ngx-dmt-row {
                & > td.ngx-dmt-cell {
                    cursor: inherit;
                    border-style: dashed;
                    border-right-color: @borderColor !important;
                    border-bottom: 1px dashed @borderColor;
                    &.ngx-dmt-cell-select {
                        cursor: pointer;
                        position: relative;
                        .vz-table-row-selector {
                            pointer-events: none;
                            width: 55px;
                            height: 55px;
                            & > label, & > vz-avatar {
                                position:absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                            & > label, & > vz-avatar, & > div > vz-avatar {
                                transition: opacity .3s @easeInOut;
                            }
                            & > label {
                                opacity: 0;
                                .ant-checkbox {
                                    top: 0;
                                }
                            }
                            & > vz-avatar {
                                opacity: 1;
                            }
                            & > .vz-trs-flag {
                                transition: opacity .3s @easeInOut;
                                opacity: 1;
                                & > i {
                                    display: block;
                                }
                            }
                        }
                        &:hover {
                            .vz-table-row-selector {
                                & > label {
                                    opacity: 1;
                                }
                                & > vz-avatar, & > div > vz-avatar {
                                    opacity: .3;
                                }
                                & > .vz-trs-flag {
                                    opacity: .7;
                                }
                            }
                        }
                    }
                }
                &.ngx-dmt-row-dragover {
                    &.ngx-dmt-row-drop-allowed {
                        cursor: copy;
                        box-shadow: inset rgba(@success-color, .7) 0 0 3px 0;
                    }
                    &.ngx-dmt-row-drop-forbiden {
                        cursor: no-drop;
                        box-shadow: inset rgba(@error-color, .7) 0 0 3px 0;
                    }
                }

                z-index: 1;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 0;
                    transition: all .3s;
                    pointer-events: none;
                }
                &:hover:after {
                    box-shadow: inset rgba(@primaryColor, .7) 0 0 3px 0;
                    transition: none;
                }
                &.ngx-dmt-selected:after,
                &.dmt-selected:after {
                    background-color: rgba(@primaryColor, .1) !important;
                }
                &.dmt-selected-menu:after {
                    background-color: rgba(@primaryColor, .1) !important;
                    box-shadow: inset -3px 0 0 @primaryColor;
                }
                &.td-vat > td.ngx-dmt-cell {
                    vertical-align: top;
                }
            }
        }
    }
    &.ngx-dmt-row-lines {
        table.ngx-dmt > tbody > tr.ngx-dmt-row > td.ngx-dmt-cell {
            border-bottom: 1px dotted @bgColorDD;
        }
    }
    &.ngx-dmt-clickable {
        table.ngx-dmt > tbody > tr.ngx-dmt-row > td.ngx-dmt-cell {
            cursor: pointer;
        }
    }
    &.ngx-dmt-selecting table.ngx-dmt > tbody > tr.ngx-dmt-row > td.ngx-dmt-cell.ngx-dmt-cell-select .vz-table-row-selector {
        & > label {
            opacity: 1;
        }
        & > vz-avatar, & > div > vz-avatar {
            opacity: .3;
        }
        & > .vz-trs-flag {
            opacity: .7;
        }
    }
    &.ngx-dmt-no-multiselect {
        table.ngx-dmt.ngx-dmt-header > thead > tr > th.ngx-dmt-header-cell.ngx-dmt-header-cell-select {
            pointer-events: none;
        }
    }
}
