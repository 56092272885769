.vz-backdrop {
    background-color: @backdropColor;
}

.vz-panel {
    background-color: @bgColor;
    border: 1px solid @shadowColor;
    border-radius: 4px;
    position: relative;
    padding: 20px 16px 16px 16px;
    min-height: 32px;
    flex-shrink: 0;
    .vz-panel-title {
        background-color: @bgColor;
        position: absolute;
        top: -16px;
        left: 16px;
        border-radius: 4px;
        border-top: 1px solid rgba(0,0,0,.05);
        box-shadow: 0 1px 2px @shadowColor;
        padding: 0px 24px;
        font-weight: bold;
        font-size: 18px;
        color: @primaryColorD;
    }
    .vz-panel-buttons {
        position: absolute;
        top: -12px;
        right: 16px;
        & > button {
            border-radius: 16px;
            &.ant-btn-sm {
                border-radius: 12px;
            }
        }
    }
    .vz-panel-content {
        max-height: 500px;
        @property --vz-scrollbar-bg {
            syntax: "<color>";
            inherits: true;
            initial-value: rgba(@primaryColor, .3);
        }
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-track {
            background: rgba(@primaryColor, .1);
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--vz-scrollbar-bg);
            border-radius: 8px;
        }
        &:hover {
            --vz-scrollbar-bg: rgba(@primaryColor, .5);
        }
        --vz-scrollbar-bg: rgba(@primaryColor, .2);
        transition: --vz-scrollbar-bg .3s;
    }
    &.vz-panel-foldable {
        transition: all .15s;
        .vz-panel-fold {
            cursor: pointer;
            color: @textColorL;
            background-color: @bgColor;
            box-shadow: 0 0 1px 1px @shadowColor;
            position: absolute;
            top: -16px;
            left: 8px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            text-align: center;
            transform: rotate(90deg);
            transition: all .15s;
            &::after {
                transition: all .15s;
                font-family: 'vizorro' !important;
                content: "\e90b";
                font-size: 28px;
                line-height: 1.1;
                font-weight: bold;
            }
        }
        .vz-panel-title {
            left: 48px;
            transition: all .15s;
        }
        .vz-panel-buttons {
            opacity: 1;
            transition: all .15s;
        }
        &.vz-panel-folded {
            height: 3px;
            padding: 0;
            .vz-panel-fold {
                transform: rotate(0deg);
                top: -11px;
                width: 23px;
                height: 23px;
                &::after {
                    font-size: 22px;
                    line-height: 1;
                }
            }
            .vz-panel-title {
                font-size: 14px;
                top: -12px;
            }
            .vz-panel-buttons {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
    &.vz-panel-m {
        .vz-panel-fold {
            top: -13px;
            left: 8px;
            width: 26px;
            height: 26px;
            &::after {
                font-size: 24px;
                line-height: 1.1;
            }
        }
        .vz-panel-title {
            top: -12px;
            left: 8px;
            font-size: 14px;
            padding: 0 12px;
        }
        &.vz-panel-foldable {
            .vz-panel-title {
                left: 42px;
            }
        }
    }
}

.vz-panel-info {
    border: 1px solid @shadowColor;
    border-radius: 4px;
    position: relative;
    padding: 4px 8px;
}

.vz-card, .vz-card-clickable {
    border: 1px solid @shadowColor;
    border-radius: 8px;
    padding: 8px 16px;
    background-color: @bgColor;
    &.vz-card-error {
        border-color: @errorColor;
    }
    & > div:only-child {
        border-radius: 8px;
    }
    &:not(.vz-car-overflow) {
        overflow-x: hidden;
        overflow-y: auto;
    }
}



.vz-card-clickable {
    cursor: pointer;
    transition: border-color .3s, box-shadow .3s, border-width .3s;
    &:hover {
        border-color: @accentColor;
        box-shadow: 0 0 10px 2px rgba(@accentColor, .5), inset 0 0 0 1px @accentColor;
    }
    &.vz-card-error:hover {
        border-color: @errorColor;
        box-shadow: 0 0 10px 2px rgba(@errorColor, .5), inset 0 0 0 1px @errorColor;
    }
}

.vz-form-row {
    margin-bottom: 8px;
    & > i {
        font-size: 28px;
        width: 48px;
        min-width: 48px;
        max-width: 48px;
        color: @textColorLL;
    }
    & > .vz-form-cb {
        width: 48px;
        min-width: 48px;
        max-width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.snippet-viewer-wrapper {
    width: 100%;
    position: relative;
    margin-top: 10px;
    .svw-header {
        position: absolute;
        top: -10px;
        left: 20px;
        font-size: 16px;
    }
    .svw-footer {
        position: absolute;
        bottom: -10px;
        left: 20px;
    }
    .svw-editor {
        border: 1px solid @borderColor;
        border-radius: 8px;
        padding: 8px 16px;
        pre {
            box-shadow: none;
            background-color: @bgColor;
            overflow: auto;
            padding: 8px 0;
            & > code {
                font-size: 14px;
                background-color: @bgColor;
                white-space: pre-wrap;
            }
        }
    }
    &.svw-collapsed .svw-editor {
        pre {
            cursor: default;
            pointer-events: none;
            user-select: none;
            max-height: 140px;
            overflow: hidden;
            margin-bottom: 0;
            mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
            -webkit-mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
        }
    }
}
