table.vz-chart-table-bar {
    & > tr {
        & > td {
            padding: 2px 4px;
            &.vz-chart-vis {
                border-left: 1px solid @borderColor;
            }
        }
        &:not(.vz-char-noborder) {
            & > td:not(:first-child) {
                border-top: 1px solid @borderColor;
            }
            &:first-child > td {
                border-top: none;
            }
        }
    }
}
.vz-chart-bar {
    transition: all .5s cubic-bezier(.26,1.22,.51,1.2);
    min-height: 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: linear-gradient(45deg, @primaryColorL, lighten(@primaryColorL, 15%));
}
.vz-chart-datalabel {
    color: @textColorL;
    margin-left: 4px;
}

.vz-chart-tooltip-container {
    padding: 4px 8px;
    background-color: @bgColor;
    border-radius: 4px;
    border: 1px solid @borderColor;
}
