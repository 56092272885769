.vz-badge,
.vz-badge-l,
.vz-badge-ll,
.vz-badge-a,
.vz-badge-a-bg,
.vz-badge-ok,
.vz-badge-err,
.vz-badge-err-bg,
.vz-badge-warn {
    border-radius: 9px;
    background-color: @primaryColor;
    font-size: 10px;
    font-weight: bold;
    color: @textColorInv;
    padding: 0 3px;
    box-shadow: 0 0 0 1px #fff;
    margin: 1px;
    min-width: 15px;
    text-align: center;
    pointer-events: none;
    &.vz-badge-xl {
        font-size: 12px;
        padding: 0 4px;
        min-width: 20px;
        border-radius: 10px;
    }
    &.vz-badge-xxl {
        font-size: 14px;
        padding: 0 5px;
        min-width: 24px;
        border-radius: 12px;
    }
}
.vz-badge-l {
    background-color: @textColorL;
    box-shadow: inset 0 0 0 1px @bgColor;
}
.vz-badge-ll {
    background-color: @textColorLL;
    box-shadow: inset 0 0 0 1px @bgColor;
}
.vz-badge-a {
    color: @accentColor;
    background-color: @bgColor;
    box-shadow: inset 0 0 0 1px @accentColor;
}
.vz-badge-a-bg {
    color: @bgColor;
    background-color: @accentColor;
    box-shadow: inset 0 0 0 1px @accentColor;
}
.vz-badge-ok {
    color: @textColorInv;
    background-color: @okColorD;
    box-shadow: inset 0 0 0 1px @okColorD;
}
.vz-badge-err {
    color: @errorColor;
    background-color: @bgColor;
    box-shadow: inset 0 0 0 1px @errorColor;
}
.vz-badge-err-bg {
    color: @bgColor;
    background-color: @errorColor;
    box-shadow: inset 0 0 1px @bgColor, 0 0 0 1px @shadowColor;
}
.vz-badge-warn {
    color: @warningColor;
    background-color: @bgColor;
    box-shadow: inset 0 0 0 1px @warningColor;
}

.vz-badge-wrapper {
    position: relative;
    & > .vz-badge,
    & > .vz-badge-l,
    & > .vz-badge-ll,
    & > .vz-badge-a,
    & > .vz-badge-warn {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.vz-project-abbr-badge {
    color: @textColorInv;
    border-radius: 4px;
    padding: 1px 4px;
    white-space: nowrap;
    line-height: 1.2;
    display: inline-block;
}

.vz-chips {
    .vz-chip {
        &:not(:first-child) {
            margin-left: 2px;
        }
        &:not(:last-child) {
            margin-right: 2px;
        }
    }
}

.vz-chip {
    color: @primaryColorD;
    background-color: rgba(@primaryColor, .1);
    border: 1px solid @primaryColor;
    border-radius: 8px;
    padding: 2px 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    & > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    & > i {
        margin-left: 4px;
        color: @textColorLL;
        &:hover, &:active {
            color: @errorColor;
        }
    }
}
